<template>
  <div class="inner-pages homepage-4 agents list hp-6 full hd-white">
    <form-wizard
      finishButtonText="Submit Property"
      :title="''"
      :subtitle="''"
      :color="'#548f4d'"
      class="formWizard"
      ref="formWizard"
    >
      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left d-none">
          <wizard-button
            @click.native="props.prevTab()"
            ref="prevButton"
            v-if="props.activeTabIndex > 0"
            :style="props.fillButtonStyle"
            >Previous</wizard-button
          >
        </div>
        <div class="d-none wizard-footer-right">
          <wizard-button
            ref="nextButton"
            v-if="!props.isLastStep"
            @click.native="props.nextTab()"
            class="wizard-footer-right"
            :style="props.fillButtonStyle"
            >Next</wizard-button
          >

          <wizard-button
            v-else
            @click.native="alert('Done')"
            class="wizard-footer-right finish-button"
            :style="props.fillButtonStyle"
            >{{ props.isLastStep ? "Create" : "Next" }}</wizard-button
          >
        </div>
      </template>

      <tab-content :before-change="check4" title="About Property">
        <div class="inner-pages maxw1600 m0a dashboard-bd">
          <div id="wrapper" class="int_main_wraapper">
            <section class="user-page section-padding pt-5">
              <div class="container">
                <div class="row">
                  <div
                    class="
                      col-lg-12 col-md-12 col-xs-12
                      royal-add-property-area
                      section_100
                      pl-0
                      user-dash2
                    "
                  >
                    <div class="single-add-property">
                      <div class="property-form-group">
                        <div class="row d-flex justify-content-center">
                          <div class="col-lg-8 col-md-12">
                            <p class="no-mb input-icon">
                              <label for="price"
                                >Price

                                <i
                                  v-b-popover.hover.right="
                                    'This is the price that your house will be listed for on Vencasa. You can amend the price at a later date. There are online calculators available for free to help with your valuation.'
                                  "
                                  class="fa fa-question-circle"
                                  aria-hidden="true"
                                ></i>
                                <b-popover
                                  target="popover-target-1"
                                  triggers="hover"
                                  placement="right"
                                >
                                </b-popover>
                              </label>

                              <input
                                :class="
                                  $v.price.$dirty && !$v.price.required
                                    ? 'error-border'
                                    : ''
                                "
                                :value="price"
                                @input="addCommas($event)"
                                @keydown="inputCheck($event)"
                                type="text"
                                name="price"
                                placeholder="Price"
                                id="price"
                                style="padding-left: 25px"
                              />
                              <i class="priceicon">£</i>
                            </p>
                            <p
                              class="error"
                              v-if="$v.price.$dirty && !$v.price.required"
                            >
                              This field is required!
                            </p>
                          </div>
                          <div class="col-lg-8 col-md-12 mt-3">
                            <div class="dropdown faq-drop">
                              <label for="dropdowntype">Property Type</label>
                              <select
                                @change="setDescriptionTitle"
                                :class="
                                  $v.type.$dirty && !$v.type.required
                                    ? 'error-border'
                                    : ''
                                "
                                v-model="type"
                                class="
                                  color-black
                                  form-select
                                  pro-status
                                  dropdown-toggle
                                  arrows
                                "
                                aria-label="Default select example"
                              >
                                <option
                                  class="optionfiledfont"
                                  value="bungalow"
                                >
                                  Bungalow
                                </option>
                                <option
                                  class="optionfiledfont"
                                  value="terraced"
                                >
                                  Terraced
                                </option>
                                <option
                                  class="optionfiledfont"
                                  value="detached"
                                >
                                  Detached
                                </option>
                                <option
                                  class="optionfiledfont"
                                  value="semi-detached"
                                >
                                  Semi-Detached
                                </option>
                                <option class="optionfiledfont" value="flat">
                                  Flat
                                </option>
                                <option class="optionfiledfont" value="land">
                                  Land
                                </option>
                                <option class="optionfiledfont" value="none">
                                  None
                                </option>
                              </select>
                              <p
                                class="error"
                                v-if="$v.type.$dirty && !$v.type.required"
                              >
                                This field is required!
                              </p>
                            </div>
                          </div>
                          <div class="col-lg-8 col-md-12">
                            <div class="dropdown faq-drop no-mb">
                              <label for="age">Tenure</label>
                              <select
                                @change="setDescriptionTitle"
                                :class="
                                  $v.tenure.$dirty && !$v.tenure.required
                                    ? 'error-border'
                                    : ''
                                "
                                v-model="tenure"
                                class="
                                  color-black
                                  form-select
                                  pro-status
                                  dropdown-toggle
                                  arrows
                                "
                                aria-label="Default select example"
                              >
                                <option class="optionfiledfont" value="free">
                                  Freehold
                                </option>
                                <option class="optionfiledfont" value="lease">
                                  Leasehold
                                </option>
                              </select>
                              <p
                                class="error"
                                v-if="$v.tenure.$dirty && !$v.tenure.required"
                              >
                                This field is required!
                              </p>
                            </div>
                          </div>
                          <div class="col-lg-8 col-md-12 mt-3 res-move-mt">
                            <div class="dropdown faq-drop no-mb">
                              <label for="bed">Number of Bedrooms</label>
                              <select
                                @input="
                                  addToDescription(
                                    'bedroom',
                                    $event.target.value
                                  )
                                "
                                :value="bedroom.length"
                                class="
                                  color-black
                                  form-select
                                  pro-status
                                  dropdown-toggle
                                  arrows
                                "
                                aria-label="Default select example"
                              >
                                <option class="optionfiledfont" value="0">
                                  None
                                </option>
                                <option class="optionfiledfont" value="1">
                                  1
                                </option>
                                <option class="optionfiledfont" value="2">
                                  2
                                </option>
                                <option class="optionfiledfont" value="3">
                                  3
                                </option>
                                <option class="optionfiledfont" value="4">
                                  4
                                </option>
                                <option class="optionfiledfont" value="5">
                                  5
                                </option>
                                <option class="optionfiledfont" value="6">
                                  6
                                </option>
                                <option class="optionfiledfont" value="7">
                                  7
                                </option>
                                <option class="optionfiledfont" value="8">
                                  8
                                </option>
                                <option class="optionfiledfont" value="9">
                                  9
                                </option>
                                <option class="optionfiledfont" value="10">
                                  10
                                </option>
                                <option class="optionfiledfont" value="11">
                                  11
                                </option>
                                <option class="optionfiledfont" value="12">
                                  12+
                                </option>
                              </select>
                            </div>
                          </div>

                          <div class="col-lg-8 col-md-12 mt-3">
                            <div class="dropdown faq-drop no-mb last">
                              <label for="bath">Number of Bathrooms</label>
                              <select
                                @input="
                                  addToDescription(
                                    'bathroom',
                                    $event.target.value
                                  )
                                "
                                :value="bathroom.length"
                                class="
                                  color-black
                                  form-select
                                  pro-status
                                  dropdown-toggle
                                  arrows
                                "
                                aria-label="Default select example"
                              >
                                <option class="optionfiledfont" value="0">
                                  None
                                </option>
                                <option class="optionfiledfont" value="1">
                                  1
                                </option>
                                <option class="optionfiledfont" value="2">
                                  2
                                </option>
                                <option class="optionfiledfont" value="3">
                                  3
                                </option>
                                <option class="optionfiledfont" value="4">
                                  4
                                </option>
                                <option class="optionfiledfont" value="5">
                                  5
                                </option>
                                <option class="optionfiledfont" value="6">
                                  6
                                </option>
                                <option class="optionfiledfont" value="7">
                                  7
                                </option>
                                <option class="optionfiledfont" value="8">
                                  8
                                </option>
                                <option class="optionfiledfont" value="9">
                                  9
                                </option>
                                <option class="optionfiledfont" value="10">
                                  10
                                </option>
                                <option class="optionfiledfont" value="11">
                                  11
                                </option>
                                <option class="optionfiledfont" value="12">
                                  12+
                                </option>
                              </select>
                            </div>
                          </div>

                          <div class="col-lg-8 col-md-12 mt-3">
                            <div class="dropdown faq-drop no-mb">
                              <label for="bed">Number of Other Rooms</label>
                              <select
                                @input="
                                  addToDescription(
                                    'otherroom',
                                    $event.target.value
                                  )
                                "
                                :value="otherroom.length"
                                class="
                                  color-black
                                  form-select
                                  pro-status
                                  dropdown-toggle
                                  arrows
                                "
                                aria-label="Default select example"
                              >
                                <option class="optionfiledfont" value="0">
                                  None
                                </option>
                                <option class="optionfiledfont" value="1">
                                  1
                                </option>
                                <option class="optionfiledfont" value="2">
                                  2
                                </option>
                                <option class="optionfiledfont" value="3">
                                  3
                                </option>
                                <option class="optionfiledfont" value="4">
                                  4
                                </option>
                                <option class="optionfiledfont" value="5">
                                  5
                                </option>
                                <option class="optionfiledfont" value="6">
                                  6
                                </option>
                                <option class="optionfiledfont" value="7">
                                  7
                                </option>
                                <option class="optionfiledfont" value="8">
                                  8
                                </option>
                                <option class="optionfiledfont" value="9">
                                  9
                                </option>
                                <option class="optionfiledfont" value="10">
                                  10
                                </option>
                                <option class="optionfiledfont" value="11">
                                  11
                                </option>
                                <option class="optionfiledfont" value="12">
                                  12+
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-lg-8 col-md-12 mt-3">
                            <div class="dropdown faq-drop no-mb">
                              <label for="age">Garage</label>
                              <select
                                v-model="garage"
                                class="
                                  color-black
                                  form-select
                                  pro-status
                                  dropdown-toggle
                                  arrows
                                "
                                aria-label="Default select example"
                              >
                                <option class="optionfiledfont" value="single">
                                  Single
                                </option>
                                <option class="optionfiledfont" value="double">
                                  Double
                                </option>
                                <option class="optionfiledfont" value="other">
                                  Other
                                </option>
                                <option class="optionfiledfont" value="none">
                                  None
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-lg-8 col-md-12 mt-3">
                            <div class="dropdown faq-drop">
                              <label for="dropdowntype">Swimming Pool</label>
                              <select
                                v-model="swimmingPool"
                                class="
                                  color-black
                                  form-select
                                  pro-status
                                  dropdown-toggle
                                  arrows
                                "
                                aria-label="Default select example"
                              >
                                <option class="optionfiledfont" value="indoor">
                                  Indoor
                                </option>
                                <option class="optionfiledfont" value="outdoor">
                                  Outdoor
                                </option>
                                <option class="optionfiledfont" value="none">
                                  None
                                </option>
                              </select>
                            </div>
                          </div>

                          <div class="col-lg-8 col-md-12">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="d-flex for-the-line">
                                  <label
                                    class="res-pt-0"
                                    style="
                                      padding-top: 12px;
                                      padding-right: 10px;
                                    "
                                  >
                                    Parking
                                  </label>

                                  <select
                                    style="text-align: left"
                                    class="
                                      arrows
                                      pakring-set
                                      color-black
                                      res-ml-0 res-parkking
                                    "
                                    v-model="parkingType"
                                  >
                                    <option
                                      class="optionfiledfont"
                                      value="onStreet"
                                    >
                                      On Street
                                    </option>
                                    <option
                                      class="optionfiledfont"
                                      value="offStreet"
                                    >
                                      Off Street
                                    </option>
                                    <option
                                      class="optionfiledfont"
                                      value="allocated"
                                    >
                                      Allocated
                                    </option>
                                    <option
                                      class="optionfiledfont"
                                      value="permitted"
                                    >
                                      Permitted
                                    </option>
                                    <option
                                      class="optionfiledfont"
                                      value="none"
                                    >
                                      No Parking
                                    </option>
                                  </select>
                                  <label
                                    class="res-pt-0 res-display-sm-hide"
                                    style="
                                      padding-top: 12px;
                                      padding-right: 12px;
                                    "
                                  >
                                    For
                                  </label>
                                  <input
                                    @click="inFocus(false)"
                                    v-model="noOfCars"
                                    style="
                                      width: 75px;
                                      height: 50px;
                                      border: 1px solid #ddd;
                                      text-align: center;
                                    "
                                    min="0"
                                    type="number"
                                    class="for-responsive-width d-noneneo"
                                  />

                                  <label
                                    class="d-noneneo"
                                    style="padding-top: 12px; padding-left: 6px"
                                  >
                                    Cars
                                  </label>
                                </div>
                              </div>
                              <div
                                class="col-md-6 res-disply-big-hide res-mt-2"
                              >
                                <div class="d-flex for-the-line">
                                  <label
                                    class="res-pt-0"
                                    style="padding-top: 12px"
                                  >
                                    For
                                  </label>
                                  <input
                                    @click="inFocus(false)"
                                    v-model="noOfCars"
                                    style="
                                      width: 100% !important;
                                      height: 50px;
                                      border: 1px solid #ddd;
                                      padding: 10px;
                                      margin-top: 0px !important;
                                    "
                                    min="0"
                                    type="number"
                                    class="for-responsive-width"
                                  />
                                  <label
                                    class="res-pt-0"
                                    style="padding-top: 12px !important"
                                  >
                                    Cars
                                  </label>
                                </div>
                              </div>
                              <div
                                class="col-md-6 res-disply-big-hide res-mt-2"
                              >
                                <div class="d-flex for-the-line">
                                  <label
                                    class="res-pt-0"
                                    for="checkbox"
                                    style="padding-top: 13px"
                                  >
                                    <input
                                      id="checkbox"
                                      v-model="isGardenChecked"
                                      type="checkbox"
                                      style="
                                        margin-top: 19px !important;
                                        transform: scale(1.3);
                                        border: 1px solid rgb(221, 221, 221);
                                      "
                                    />
                                    Garden Approx
                                  </label>
                                  <input
                                    @click="inFocus(false)"
                                    :disabled="!isGardenChecked"
                                    v-model="garden"
                                    style="
                                      width: 100%;
                                      height: 50px;
                                      border: 1px solid #ddd;
                                      padding: 10px;
                                    "
                                    min="0"
                                    type="number"
                                  />
                                  <select
                                    style="
                                      width: 100% !important;
                                      margin-top: 11px;
                                      margin-left: 0px;
                                      text-align: left;
                                      padding: 10px;
                                    "
                                    class="arrows pakring-set color-black mr-0"
                                    v-model="gardenArea"
                                  >
                                    <option
                                      class="optionfiledfont"
                                      value="acres"
                                    >
                                      Acres
                                    </option>
                                    <option
                                      class="optionfiledfont"
                                      value="sqMeters"
                                    >
                                      Sq. Meters
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div
                                class="col-md-12 res-display-sm-hide res-mt-3"
                              >
                                <div class="mt-3">
                                  <input
                                    id="checkbox"
                                    v-model="isGardenChecked"
                                    type="checkbox"
                                    style="
                                      margin-top: 19px !important;
                                      transform: scale(1.5);
                                      border: 1px solid rgb(221, 221, 221);
                                      margin-right: 5px;
                                    "
                                  />
                                  <label
                                    for="checkbox"
                                    style="
                                      padding-top: 13px;
                                      padding-right: 10px;
                                      padding-left: 3px;
                                    "
                                    >Garden Approx
                                  </label>

                                  <input
                                    @click="inFocus(false)"
                                    :disabled="!isGardenChecked"
                                    v-model="garden"
                                    style="
                                      width: 59%;
                                      height: 50px;
                                      border: 1px solid #ddd;
                                      text-align: left;
                                      padding-left: 12px;
                                    "
                                    min="0"
                                    type="number"
                                  />
                                  &nbsp;&nbsp;&nbsp;
                                  <select
                                    style="width: 109px !important"
                                    class="arrows pakring-set color-black mr-0"
                                    v-model="gardenArea"
                                  >
                                    <option
                                      class="optionfiledfont"
                                      value="acres"
                                    >
                                      Acres
                                    </option>
                                    <option
                                      class="optionfiledfont"
                                      value="sqMeters"
                                    >
                                      Sq. Meters
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-center mt-4">
                          <div
                            style="
                              width: 100%;
                              height: 5vh;
                              background-color: #f6f6f6;
                            "
                          ></div>
                        </div>
                        <div class="row ml-1 r-float">
                          <button
                            v-if="
                              $refs.formWizard
                                ? $refs.formWizard.activeTabIndex > 0 &&
                                  !$refs.formWizard.isLastStep
                                : false
                            "
                            class="
                              res-back-button
                              btn btn-yellow
                              listing-button-color
                              px-6
                              mt-3
                              w135
                              pull-left
                              setleft
                            "
                            @click="back()"
                          >
                            Back
                          </button>

                          <button
                            v-if="
                              $refs.formWizard
                                ? !$refs.formWizard.isLastStep
                                : true
                            "
                            class="
                              btn btn-yellow
                              w135
                              listing-button-color
                              res-b-bacck-1
                              mt-3
                              px-6
                              pull-right
                              ml-auto
                              setleft
                              mr-3
                            "
                            @click="next()"
                          >
                            <b-spinner small v-if="showSpinner"></b-spinner
                            >{{
                              !showSpinner
                                ? $refs.formWizard
                                  ? $refs.formWizard.isLastStep
                                    ? "Create"
                                    : "Next"
                                  : "Next"
                                : ""
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </tab-content>
      <tab-content :before-change="checkDescription" title="Description">
        <div v-if="listing" class="inner-pages maxw1600 m0a dashboard-bd">
          <div id="wrapper" class="int_main_wraapper">
            <section class="user-page section-padding pt-5">
              <div class="container">
                <div class="row">
                  <div
                    class="
                      col-lg-12 col-md-12 col-xs-12
                      royal-add-property-area
                      section_100
                      pl-0
                      user-dash2
                    "
                  >
                    <div class="single-add-property">
                      <div class="property-form-group">
                        <form>
                          <div class="row">
                            <div class="col-lg-12 col-md-12">
                              <h3 style="font-weight: 700">
                                Write a short description about your property.
                              </h3>
                              <p
                                class="txt-style"
                                style="font-weight: 600; font-size: 18px"
                              >
                                Whether you want to keep it simple or go into
                                more detail, it is important to make sure your
                                descriptons are factual. <br />
                                For more advice on descriptions, read our guide
                                <strong>here</strong>.
                              </p>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                              <label class="font-weight-bolder"
                                >Description for Main Page</label
                              >
                              <p>
                                <textarea
                                  @change="isDescriptionChanged = true"
                                  :class="
                                    $v.mainDescription.$dirty &&
                                    !$v.mainDescription.required
                                      ? 'error-border'
                                      : ''
                                  "
                                  id="mainDescription"
                                  @input="inputLimitClipboard"
                                  v-model="mainDescription"
                                  name="pro-dexc"
                                ></textarea>
                                <label class="float-right"
                                  >{{ mainDescription.length }}/250</label
                                >
                              </p>
                              <p
                                class="error mt-n4"
                                v-if="
                                  $v.mainDescription.$dirty &&
                                  !$v.mainDescription.required
                                "
                              >
                                This field is required!
                              </p>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                              <input
                                class="mb-2 w-100"
                                type="text"
                                v-model="mainPageDescriptionTitle"
                              />
                              <p>
                                <textarea
                                  @change="isDescriptionChanged = true"
                                  id="mainPageDescription"
                                  v-model="mainPageDescription"
                                  name="pro-dexc"
                                ></textarea>
                              </p>
                            </div>
                            <div
                              v-if="
                                tenure == 'lease' &&
                                !removedList['tenureDescription']
                              "
                              class="col-lg-12 col-md-12 mt-3"
                            >
                              <input
                                class="mb-2 w-100"
                                type="text"
                                v-model="tenureDescriptionTitle"
                              />
                              <p>
                                <textarea
                                  @click="inFocus(true)"
                                  id="tenureDescription"
                                  @change="isDescriptionChanged = true"
                                  v-model="tenureDescription"
                                  name="pro-dexc"
                                ></textarea>
                                <i
                                  @click="
                                    removeOtherThanBathBedOther(
                                      'tenureDescription'
                                    )
                                  "
                                  class="
                                    float-right
                                    mb-4
                                    hover
                                    fas
                                    fa-trash-alt
                                  "
                                ></i>
                              </p>
                            </div>
                            <div
                              class="col-lg-12 col-md-12 mt-3"
                              v-for="room in bedroom"
                              :key="'bedroom' + room.order"
                            >
                              <input
                                class="mb-2 w-100"
                                type="text"
                                v-model="findBedroomDescription(room.order).key"
                              />
                              <p>
                                <textarea
                                  @change="isDescriptionChanged = true"
                                  type="text"
                                  v-model="
                                    findBedroomDescription(room.order).value
                                  "
                                ></textarea>
                                <i
                                  @click="
                                    removeBedBathOther(
                                      'bedroom',
                                      findBedroomDescription(room.order).key,
                                      room.order
                                    )
                                  "
                                  class="
                                    float-right
                                    mb-4
                                    hover
                                    fas
                                    fa-trash-alt
                                  "
                                ></i>
                              </p>
                            </div>
                            <div
                              class="col-lg-12 col-md-12 mt-3"
                              v-for="room in bathroom"
                              :key="'bathroom' + room.order"
                            >
                              <input
                                class="mb-2 w-100"
                                type="text"
                                v-model="
                                  findBathroomDescription(room.order).key
                                "
                              />
                              <p>
                                <textarea
                                  @change="isDescriptionChanged = true"
                                  type="text"
                                  v-model="
                                    findBathroomDescription(room.order).value
                                  "
                                ></textarea>
                                <i
                                  @click="
                                    removeBedBathOther(
                                      'bathroom',
                                      findBathroomDescription(room.order).key,
                                      room.order
                                    )
                                  "
                                  class="
                                    float-right
                                    mb-4
                                    hover
                                    fas
                                    fa-trash-alt
                                  "
                                ></i>
                              </p>
                            </div>
                            <div
                              class="col-lg-12 col-md-12 mt-3"
                              v-for="room in otherroom"
                              :key="'otherroom' + room.order"
                            >
                              <input
                                class="mb-2 w-100"
                                type="text"
                                v-model="
                                  findOtherroomDescription(room.order).key
                                "
                              />
                              <p>
                                <textarea
                                  @change="isDescriptionChanged = true"
                                  type="text"
                                  v-model="
                                    findOtherroomDescription(room.order).value
                                  "
                                ></textarea>
                                <i
                                  @click="
                                    removeBedBathOther(
                                      'otherroom',
                                      findOtherroomDescription(room.order).key,
                                      room.order
                                    )
                                  "
                                  class="
                                    float-right
                                    mb-4
                                    hover
                                    fas
                                    fa-trash-alt
                                  "
                                ></i>
                              </p>
                            </div>
                            <div
                              v-if="
                                !removedList['garageDescription'] &&
                                garage &&
                                garage != 'none'
                              "
                              class="col-lg-12 col-md-12 mt-3"
                            >
                              <input
                                class="mb-2 w-100"
                                type="text"
                                v-model="garageDescriptionTitle"
                              />
                              <p>
                                <textarea
                                  @click="inFocus(true)"
                                  id="garageDescription"
                                  @change="isDescriptionChanged = true"
                                  v-model="garageDescription"
                                  name="pro-dexc"
                                ></textarea>
                                <i
                                  @click="
                                    removeOtherThanBathBedOther(
                                      'garageDescription'
                                    )
                                  "
                                  class="
                                    float-right
                                    mb-4
                                    hover
                                    fas
                                    fa-trash-alt
                                  "
                                ></i>
                              </p>
                            </div>
                            <div
                              v-if="
                                !removedList['swimmingPoolDescription'] &&
                                swimmingPool &&
                                swimmingPool != 'none'
                              "
                              class="col-lg-12 col-md-12 mt-3"
                            >
                              <input
                                class="mb-2 w-100"
                                type="text"
                                v-model="swimmingPoolDescriptionTitle"
                              />
                              <p>
                                <textarea
                                  @click="inFocus(true)"
                                  id="swimmingPoolDescription"
                                  @change="isDescriptionChanged = true"
                                  v-model="swimmingPoolDescription"
                                  name="pro-dexc"
                                ></textarea>
                                <i
                                  @click="
                                    removeOtherThanBathBedOther(
                                      'swimmingPoolDescription'
                                    )
                                  "
                                  class="
                                    float-right
                                    mb-4
                                    hover
                                    fas
                                    fa-trash-alt
                                  "
                                ></i>
                              </p>
                            </div>
                            <div
                              v-if="
                                !removedList['parkingDescription'] &&
                                parkingType &&
                                parkingType != 'none'
                              "
                              class="col-lg-12 col-md-12 mt-3"
                            >
                              <input
                                class="mb-2 w-100"
                                type="text"
                                v-model="parkingDescriptionTitle"
                              />
                              <p>
                                <textarea
                                  @click="inFocus(true)"
                                  id="parkingDescription"
                                  @change="isDescriptionChanged = true"
                                  v-model="parkingDescription"
                                  name="pro-dexc"
                                ></textarea>
                                <i
                                  @click="
                                    removeOtherThanBathBedOther(
                                      'parkingDescription'
                                    )
                                  "
                                  class="
                                    float-right
                                    mb-4
                                    hover
                                    fas
                                    fa-trash-alt
                                  "
                                ></i>
                              </p>
                            </div>
                            <div
                              v-if="
                                !removedList['gardenDescription'] &&
                                garden &&
                                isGardenChecked
                              "
                              class="col-lg-12 col-md-12 mt-3"
                            >
                              <input
                                class="mb-2 w-100"
                                type="text"
                                v-model="gardenDescriptionTitle"
                              />
                              <p>
                                <textarea
                                  @click="inFocus(true)"
                                  id="gardenDescription"
                                  @change="isDescriptionChanged = true"
                                  v-model="gardenDescription"
                                  name="pro-dexc"
                                ></textarea>
                                <i
                                  @click="
                                    removeOtherThanBathBedOther(
                                      'gardenDescription'
                                    )
                                  "
                                  class="
                                    float-right
                                    mb-4
                                    hover
                                    fas
                                    fa-trash-alt
                                  "
                                ></i>
                              </p>
                            </div>
                            <div
                              class="col-lg-12 col-md-12 mt-3"
                              v-for="room in customRooms"
                              :key="'custom' + room.order"
                            >
                              <input
                                class="mb-2 w-100"
                                type="text"
                                v-model="
                                  findCustomroomDescription(room.order).key
                                "
                              />
                              <p>
                                <textarea
                                  @change="isDescriptionChanged = true"
                                  type="text"
                                  v-model="
                                    findCustomroomDescription(room.order).value
                                  "
                                ></textarea>
                                <i
                                  @click="
                                    removeCustomBox(
                                      findCustomroomDescription(room.order)
                                        .label,
                                      findCustomroomDescription(room.order)
                                        .order
                                    )
                                  "
                                  class="
                                    float-right
                                    mb-4
                                    hover
                                    fas
                                    fa-trash-alt
                                  "
                                ></i>
                              </p>
                            </div>
                          </div>
                          <div class="d-flex justify-content-end mr-0">
                            <button
                              ref="button1"
                              @click="addToDescription('custom')"
                              style="
                                margin-top: 0px;
                                width: 42px;
                                float: right;
                                color: white;
                              "
                              type="button"
                              class="
                                btn btn-primary
                                listing-button-color
                                res-button-how
                              "
                            >
                              +
                            </button>
                          </div>
                        </form>
                        <div class="d-flex justify-content-center mt-4">
                          <div
                            style="
                              width: 100%;
                              height: 5vh;
                              background-color: #f6f6f6;
                            "
                          ></div>
                        </div>
                        <div class="row ml-1">
                          <button
                            style="color: white"
                            v-if="
                              $refs.formWizard
                                ? $refs.formWizard.activeTabIndex > 0 &&
                                  !$refs.formWizard.isLastStep
                                : false
                            "
                            class="
                              res-back-button
                              btn btn-yellow
                              listing-button-color
                              px-6
                              mt-3
                              w135
                              pull-left
                              setleft
                            "
                            @click="back()"
                          >
                            Back
                          </button>

                          <button
                            style="color: white"
                            v-if="
                              $refs.formWizard
                                ? !$refs.formWizard.isLastStep
                                : true
                            "
                            class="
                              res-next-button
                              btn btn-yellow
                              w135
                              listing-button-color
                              res-b-bacck-1
                              mt-3
                              px-6
                              pull-right
                              ml-auto
                              setleft
                              mr-3
                            "
                            @click="next()"
                          >
                            <b-spinner small v-if="showSpinner"></b-spinner
                            >{{
                              !showSpinner
                                ? $refs.formWizard
                                  ? $refs.formWizard.isLastStep
                                    ? "Create"
                                    : "Next"
                                  : "Next"
                                : ""
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </tab-content>
      <tab-content :before-change="check2" title="Photos">
        <div v-if="listing" class="inner-pages maxw1600 m0a dashboard-bd">
          <!-- Wrapper -->
          <div id="wrapper" class="int_main_wraapper">
            <section class="user-page section-padding pt-5">
              <div class="container">
                <div class="row mt-5">
                  <div class="col-lg-12 col-md-12 col-xs-12">
                    <div
                      class="res-remove-paddings-multi"
                      style="padding-left: 66px; padding-right: 80px"
                    >
                      <h3 class="pase" style="font-weight: 700">
                        Add Photos of your property
                      </h3>
                      <p class="text-style-for-mob font-set">
                        Simply take 1 exterior photo of the front of your
                        property, then extra photos to show the interior rooms
                        and garden.<br />
                        Your smartphone or digital camera will work great for
                        this, just make sure it is in landscape orientation.
                      </p>
                      <div
                        style="border-radius: 8px; background-color: #f7901d"
                      >
                        <div
                          style="
                            padding-left: 10px;
                            padding-top: 10px;
                            padding-bottom: 10px;
                          "
                        >
                          <i
                            class="fas fa-exclamation-triangle"
                            style="
                              display: inline;
                              font-size: 33px;
                              padding-top: 3px;
                              color: white;
                              float: left;
                              text-align: left;
                              padding-right: 16px;
                            "
                          ></i>

                          <p
                            style="
                              font-family: 'Lato', sans-serif !important;
                              font-size: 15px !important;
                              font-weight: 400 !important;
                              color: white;

                              padding-bottom: 0px;
                              margin-bottom: 6px;
                              padding-top: 8px;
                            "
                            class="width-fix res-ml-54 font-set"
                          >
                            You can upload photos of your property later. Read
                            our guide on property photography
                            <strong>here</strong>.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="
                      col-lg-12 col-md-12 col-xs-12
                      royal-add-property-area
                      section_100
                      pl-0
                      user-dash2
                    "
                  >
                    <div class="single-add-property res-photo-pad">
                      <div class="property-form-group">
                        <div class="row">
                          <div class="col-md-12">
                            <button
                              style="
                                margin-top: 0px;
                                width: 42px;
                                float: right;
                                color: white;
                              "
                              type="button"
                              class="
                                res-hide-on-big
                                mb-2
                                btn btn-primary
                                listing-button-color
                                res-button-how
                              "
                              @click="addPhoto"
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <vue-dropzone
                              @vdropzone-removed-file="fileRemoved"
                              @vdropzone-mounted="dropzoneMounted"
                              @vdropzone-error="onError"
                              ref="dropzone"
                              @vdropzone-success="imageUploadComplete"
                              @vdropzone-sending="sendImages"
                              id="drop1"
                              :options="dropOptions"
                            ></vue-dropzone>
                            <span v-if="showError" class="error"
                              >Only image files can be uploaded, please try
                              again.</span
                            >
                            <span v-if="showLaterMessageImages" class="error"
                              >You can upload photos later.</span
                            >
                            <p
                              class="error"
                              v-if="$v.images.$dirty && !$v.images.required"
                            >
                              Add Images to continue!
                            </p>
                          </div>
                        </div>
                        <div class="d-flex justify-content-center mt-4">
                          <div
                            style="
                              width: 100%;
                              height: 5vh;
                              background-color: #f6f6f6;
                            "
                          ></div>
                        </div>
                        <div class="row ml-1">
                          <button
                            v-if="
                              $refs.formWizard
                                ? $refs.formWizard.activeTabIndex > 0 &&
                                  !$refs.formWizard.isLastStep
                                : false
                            "
                            class="
                              res-back-button
                              btn btn-yellow
                              listing-button-color
                              px-6
                              mt-3
                              w135
                              pull-left
                              setleft
                            "
                            @click="back()"
                          >
                            Back
                          </button>

                          <button
                            v-if="
                              $refs.formWizard
                                ? !$refs.formWizard.isLastStep
                                : true
                            "
                            class="
                              res-next-button
                              btn btn-yellow
                              w135
                              listing-button-color
                              res-b-bacck-1
                              mt-3
                              px-6
                              pull-right
                              ml-auto
                              setleft
                              mr-3
                            "
                            @click="next()"
                          >
                            <b-spinner small v-if="showSpinner"></b-spinner
                            >{{
                              !showSpinner
                                ? $refs.formWizard
                                  ? $refs.formWizard.isLastStep
                                    ? "Create"
                                    : "Next"
                                  : "Next"
                                : ""
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </tab-content>
      <tab-content :before-change="checkImage" title="Floor Plan">
        <div v-if="listing" class="inner-pages maxw1600 m0a dashboard-bd">
          <!-- Wrapper -->
          <div id="wrapper" class="int_main_wraapper">
            <section class="user-page section-padding pt-5">
              <div class="container">
                <div class="row mt-5">
                  <div class="col-lg-12 col-md-12 col-xs-12">
                    <div
                      class="res-remove-paddings-multi"
                      style="padding-left: 66px; padding-right: 80px"
                    >
                      <h3 style="font-weight: 700">Upload your Floorplan</h3>

                      <div
                        style="border-radius: 8px; background-color: #f7901d"
                        class="yellew-text"
                      >
                        <div
                          style="
                            padding-left: 10px;
                            padding-top: 10px;
                            padding-bottom: 10px;
                          "
                        >
                          <i
                            class="fas fa-exclamation-triangle"
                            style="
                              display: inline;
                              font-size: 33px;
                              padding-top: 3px;
                              color: white;
                              float: left;
                              text-align: left;
                              padding-right: 16px;
                            "
                          ></i>

                          <p
                            class="font-set"
                            style="
                              color: white;
                              font-size: 21px;
                              padding-bottom: 0px;
                              margin-bottom: 6px;
                              padding-top: 8px;
                            "
                          >
                            You can upload photos of your floorplan later. Read
                            our guide on creating a floorplan
                            <strong>here</strong>.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      col-lg-12 col-md-12 col-xs-12
                      royal-add-property-area
                      section_100
                      pl-0
                      user-dash2
                    "
                  >
                    <div class="single-add-property res-photo-pad">
                      <div class="property-form-group">
                        <div class="row">
                          <div class="col-md-12">
                            <button
                              style="
                                margin-top: 0px;
                                width: 42px;
                                float: right;
                                color: white;
                              "
                              type="button"
                              class="
                                res-hide-on-big
                                mb-2
                                btn btn-primary
                                listing-button-color
                                res-button-how
                              "
                              @click="addPhotoFloorPlan"
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div class="row d-flex justify-content-center">
                          <div class="col-lg-12 col-md-12">
                            <vue-dropzone
                              @vdropzone-removed-file="fileRemoved2"
                              @vdropzone-mounted="dropzoneMountedFloorPlan"
                              ref="dropzone2"
                              @vdropzone-success="imageUploadComplete2"
                              @vdropzone-sending="sendImages2"
                              id="drop1"
                              :options="dropOptionsFloorPlan"
                            ></vue-dropzone>
                            <span v-if="showLaterMessageFloorPlan" class="error"
                              >You can upload floor plan later.</span
                            >
                            <p
                              class="error"
                              v-if="
                                $v.floorPlan.$dirty && !$v.floorPlan.required
                              "
                            >
                              Add Floor Plan to continue!
                            </p>
                          </div>
                        </div>
                        <div class="d-flex justify-content-center mt-4">
                          <div
                            style="
                              width: 100%;
                              height: 5vh;
                              background-color: #f6f6f6;
                            "
                          ></div>
                        </div>
                        <button
                          v-if="
                            $refs.formWizard
                              ? $refs.formWizard.activeTabIndex > 0 &&
                                !$refs.formWizard.isLastStep
                              : false
                          "
                          class="
                            res-back-button
                            btn btn-yellow
                            listing-button-color
                            px-6
                            mt-3
                            w135
                            pull-left
                            setleft
                          "
                          @click="back()"
                        >
                          Back
                        </button>
                        <button
                          v-if="
                            $refs.formWizard
                              ? !$refs.formWizard.isLastStep
                              : true
                          "
                          class="
                            res-next-button
                            btn btn-yellow
                            w135
                            listing-button-color
                            res-b-bacck-1
                            mt-3
                            px-6
                            pull-right
                            ml-auto
                            setleft
                            mr-3
                          "
                          @click="next()"
                        >
                          <b-spinner small v-if="showSpinner"></b-spinner
                          >{{
                            !showSpinner
                              ? $refs.formWizard
                                ? $refs.formWizard.isLastStep
                                  ? "Create"
                                  : "Next"
                                : "Next"
                              : ""
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </tab-content>
      <tab-content :before-change="checkEpc" title="EPC Certificate">
        <div v-if="listing" class="inner-pages maxw1600 m0a dashboard-bd">
          <!-- Wrapper -->
          <div id="wrapper" class="int_main_wraapper">
            <section class="user-page section-padding pt-5">
              <div class="container">
                <div class="row mt-5">
                  <div class="col-lg-12 col-md-12 col-xs-12">
                    <div
                      class="res-remove-paddings-multi"
                      style="padding-left: 66px; padding-right: 80px"
                    >
                      <h3 class="pase" style="font-weight: 700">
                        Select your EPC rating
                      </h3>
                      <p
                        class="text-style-for-mob font-set"
                        style="font-weight: 600; font-size: 18px"
                      >
                        Every house in the UK is rated for it's energy
                        efficiency, this is called EPC rating. There are local
                        companies who will be able to help you to get your
                        rating.
                      </p>

                      <div
                        style="border-radius: 8px; background-color: #f7901d"
                        class="width-fix12"
                      >
                        <div
                          style="
                            padding-left: 10px;
                            padding-top: 10px;
                            padding-bottom: 10px;
                          "
                        >
                          <i
                            class="fas fa-exclamation-triangle"
                            style="
                              display: inline;
                              font-size: 33px;
                              padding-top: 14px;
                              color: white;
                              float: left;
                              text-align: left;
                              padding-right: 16px;
                            "
                          ></i>

                          <p
                            style="
                              font-family: Lato, sans-serif !important;
                              font-size: 15px !important;
                              font-weight: 400 !important;
                              color: white;

                              padding-bottom: 0px;
                              margin-bottom: 6px;
                              padding-top: 8px;
                            "
                          >
                            You can list your property without an EPC
                            Certificate, however it is a legal requirement to
                            provide one in order to accept an offer on your
                            Property. You can add one to your lisitng later.
                            <br />
                            <br />
                          </p>
                          <p
                            class="res-pl-0 font-set"
                            style="
                              padding-left: 49px;
                              color: white;
                              font-size: 21px;
                              font-weight: 600;
                            "
                          >
                            Click 'Next' to proceed.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      col-lg-12 col-md-12 col-xs-12
                      royal-add-property-area
                      section_100
                      pl-0
                      user-dash2
                    "
                  >
                    <div class="single-add-property res-photo-pad">
                      <div class="property-form-group">
                        <div class="row d-flex justify-content-center">
                          <div class="col-lg-12 col-md-12">
                            <select
                              class="
                                color-black
                                form-select
                                pro-status
                                dropdown-toggle
                                arrows
                              "
                              place
                              v-model="epcRating"
                              name=""
                              id=""
                            >
                              <option value="" disabled selected>
                                Select EPC Rating
                              </option>
                              <option value="A">A</option>
                              <option value="B">B</option>
                              <option value="C">C</option>
                              <option value="D">D</option>
                              <option value="E">E</option>
                              <option value="F">F</option>
                              <option value="G">G</option>
                            </select>
                            <p
                              class="error"
                              v-if="
                                $v.epcRating.$dirty && !$v.epcRating.required
                              "
                            >
                              This field is required!
                            </p>
                            <div class="row mt-2">
                              <div class="col-md-12">
                                <button
                                  style="
                                    margin-top: 0px;
                                    width: 42px;
                                    float: right;
                                    color: white;
                                  "
                                  type="button"
                                  class="
                                    res-hide-on-big
                                    btn btn-primary
                                    listing-button-color
                                    res-button-how
                                  "
                                  @click="addPhotoEpc"
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <vue-dropzone
                              class="mt-3"
                              @vdropzone-removed-file="fileRemoved3"
                              @vdropzone-mounted="dropzoneMountedEpc"
                              ref="dropzone3"
                              @vdropzone-success="imageUploadComplete3"
                              @vdropzone-sending="sendImages3"
                              @vdropzone-file-added="dropping"
                              id="drop1"
                              :options="dropOptionsEpc"
                              :useCustomSlot="showWarningMessage"
                            >
                              <div class="dropzone-custom-content">
                                <p class="error text-left">
                                  You can list your property without an EPC
                                  certificate. However it is a legal requirement
                                  to provide one in order to accept an offer on
                                  your property.
                                </p>
                                <p class="error text-left">
                                  You can return and add one to your listing
                                  later.
                                </p>
                                <p class="error text-left">
                                  Click Next to proceed.
                                </p>
                              </div>
                            </vue-dropzone>
                            <p
                              class="error"
                              v-if="$v.epcFile.$dirty && !$v.epcFile.required"
                            >
                              Add EPC Certificate to continue!
                            </p>
                            <p class="text-style-for-mob mt-5 font-set">
                              It is a legal requirement to provide an Energy
                              Performance Certificate for buyers to view if you
                              are selling a property in the UK.
                              <br data-v-4f8ed385="" /><br data-v-4f8ed385="" />
                              An EPC certificate is valid for 10 years, so if
                              you already have one in date you can use that. You
                              can use the government's EPC checker online to see
                              if your property has one.
                              <br data-v-4f8ed385="" /><br data-v-4f8ed385="" />
                              Alternatively if you do not have one you will need
                              a 3rd party to assess your property.
                              <br data-v-4f8ed385="" /><br data-v-4f8ed385="" />
                              Please note that you can list a property without
                              an EPC but you will be unable to accept an offer
                              on your property until you have uploaded the
                              properties EPC. This can be done later by editing
                              the listing. <br data-v-4f8ed385="" /><br
                                data-v-4f8ed385=""
                              />
                              For properties in Scotland, your EPC will
                              typically be included in your 'Home Report',
                              however you can also attain an EPC independent of
                              your Home Report. You can check to see if you
                              already have a valid EPC by searching the Scottish
                              Energy Performance Register online.
                            </p>
                          </div>
                        </div>
                        <div class="d-flex justify-content-center mt-4">
                          <div
                            style="
                              width: 100%;
                              height: 5vh;
                              background-color: #f6f6f6;
                            "
                          ></div>
                        </div>
                        <div class="row ml-1">
                          <button
                            v-if="
                              $refs.formWizard
                                ? $refs.formWizard.activeTabIndex > 0 &&
                                  !$refs.formWizard.isLastStep
                                : false
                            "
                            class="
                              res-back-button
                              btn btn-yellow
                              listing-button-color
                              px-6
                              mt-3
                              w135
                              pull-left
                              setleft
                            "
                            @click="back()"
                          >
                            Back
                          </button>

                          <button
                            v-if="
                              $refs.formWizard
                                ? !$refs.formWizard.isLastStep
                                : true
                            "
                            class="
                              res-next-button
                              btn btn-yellow
                              w135
                              listing-button-color
                              res-b-bacck-1
                              mt-3
                              px-6
                              pull-right
                              ml-auto
                              setleft
                              mr-3
                            "
                            @click="next()"
                          >
                            <b-spinner small v-if="showSpinner"></b-spinner
                            >{{
                              !showSpinner
                                ? $refs.formWizard
                                  ? $refs.formWizard.isLastStep
                                    ? "Create"
                                    : "Next"
                                  : "Next"
                                : ""
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </tab-content>
      <tab-content ref="summaryTab" title="Summary">
        <div v-if="listing" class="inner-pages maxw1600 m0a dashboard-bd">
          <!-- Wrapper -->
          <div id="wrapper" class="int_main_wraapper">
            <section class="user-page section-padding pt-5">
              <div class="container">
                <div class="row">
                  <div
                    class="
                      col-lg-12 col-md-12 col-xs-12
                      royal-add-property-area
                      section_100
                      pl-0
                      user-dash2
                    "
                  >
                    <div
                      style="box-shadow: rgb(71 85 95 / 8%) 0px 0px 10px 1px"
                      class="
                        single-add-property
                        properties-list
                        paddingset
                        full
                        featured
                        portfolio
                        blog
                      "
                    >
                      <div class="">
                        <button
                          style="color: white; float: right"
                          class="btn btn-yellow re-b-g-save ress-mar-right"
                          @click="update"
                        >
                          <b-spinner small v-if="showSpinner"></b-spinner
                          >{{ "Save" }}
                        </button>

                        <div
                          style="width: 100%"
                          class="row featured portfolio-items"
                        >
                          <div class="col-lg-12 col-md-12 mt-4">
                            <simple-list-property
                              :property="{
                                images: images,
                                noOfBathrooms: bathroom.length,
                                county: county,
                                city: city,
                                street: street,
                                noOfBedrooms: bedroom.length,
                                houseNo: houseNo,
                                price: parseInt(price.replace(/,/g, '')),
                                epcCertificate: { epcRating: epcRating },
                                description: [
                                  { mainDescription: mainDescription },
                                ],
                              }"
                            >
                              <template #title>
                                <span>
                                  <i class="fa fa-map-marker"></i
                                  >{{
                                    `${city}, ${postCode.split(" ")[0]}${
                                      county ? `, ${county}` : ""
                                    }`
                                  }}
                                </span>
                              </template>
                              <template #actions>
                                <i
                                  :class="[
                                    'fas',
                                    'fa-heart',
                                    'res-icon-width',
                                    'stroke-transparent',
                                  ]"
                                  :style="'width: 100%; text-align: end; font-size: 30px'"
                                ></i>
                              </template>
                              <template #descriptionTitle="{ property }">
                                <div
                                  style="
                                    height: 91px !important;
                                    overflow-wrap: anywhere;
                                  "
                                >
                                  <span style="text-align: justify">
                                    {{
                                      property.description
                                        ? property.description[0]
                                          ? `${sliceString(
                                              property.description[0][
                                                "mainDescription"
                                              ]
                                            )}`
                                          : ""
                                        : ""
                                    }}<button
                                      class="button-link"
                                      @click="scrollToMain"
                                      v-if="
                                        (property.description
                                          ? property.description[0]
                                            ? property.description[0][
                                                'mainDescription'
                                              ]
                                            : ''
                                          : ''
                                        ).length > 250
                                      "
                                    >
                                      ...more
                                    </button></span
                                  >
                                </div>
                              </template>
                              <template #buttons>
                                <button
                                  disabled
                                  style="
                                    width: 25%;
                                    padding: 10px 0px;
                                    border: none;
                                    background-color: rgb(103 155 91);
                                    color: #fff;
                                    border-radius: 5px;
                                    font-size: 15px;
                                  "
                                >
                                  View
                                </button>
                                <button
                                  disabled
                                  style="
                                    width: 25%;
                                    padding: 10px 0px;
                                    border: none;
                                    background-color: rgb(103 155 91);
                                    color: #fff;
                                    border-radius: 5px;
                                    font-size: 15px;
                                  "
                                >
                                  Enquire
                                </button>
                              </template>
                              <template #createdAt>
                                <p class="d-none"></p>
                              </template>
                            </simple-list-property>
                          </div>
                          <div class="col-lg-12 col-md-12 mt-4">
                            <div
                              style="
                                border-radius: 8px 8px 8px 8px;
                                justify-content: center;
                              "
                              class="homes-content details row m-0 mb-30"
                            >
                              <div
                                v-if="type !== 'none'"
                                class="col-md-2 text-center res-margin-imgaes"
                              >
                                <img
                                  class="custome-images-icon res-sm-img"
                                  :src="
                                    require('../assets/images/icons/Home-Icon.webp')
                                  "
                                />
                                <p style="font-weight: bold" class="m-0">
                                  Property Type:
                                </p>
                                <span
                                  >{{ capitalize(listing.propertyType) }}
                                </span>
                              </div>
                              <div
                                class="col-md-2 text-center res-margin-imgaes"
                              >
                                <img
                                  class="custome-images-icon res-sm-img"
                                  :src="
                                    require('../assets/images/icons/Tenure-Icon.webp')
                                  "
                                />
                                <p style="font-weight: bold" class="m-0">
                                  Tenure:
                                </p>
                                <span>
                                  {{ capitalize(listing.tenure) }}hold</span
                                >
                              </div>
                              <div
                                v-if="listing.garage && garage != 'none'"
                                class="col-md-2 text-center res-margin-imgaes"
                              >
                                <img
                                  class="custome-images-icon res-sm-img"
                                  :src="
                                    require('../assets/images/icons/Garage-Icon.webp')
                                  "
                                />
                                <p style="font-weight: bold" class="m-0">
                                  Garage:
                                </p>
                                <span>{{ capitalize(listing.garage) }}</span>
                              </div>
                              <div
                                v-if="listing.parking && parkingType != 'none'"
                                class="col-md-2 text-center res-margin-imgaes"
                              >
                                <img
                                  class="custome-images-icon res-sm-img"
                                  :src="
                                    require('../assets/images/icons/Parking-Icon.webp')
                                  "
                                />
                                <p style="font-weight: bold" class="m-0">
                                  Parking:
                                </p>
                                <span
                                  >{{ capitalize(enums[listing.parking]) }}
                                  {{ listing.noOfCars }}
                                  {{
                                    `car${listing.noOfCars == 1 ? "" : "s"}`
                                  }}</span
                                >
                              </div>
                              <div
                                v-if="listing.garden"
                                class="col-md-2 text-center res-margin-imgaes"
                              >
                                <img
                                  class="custome-images-icon res-sm-img"
                                  :src="
                                    require('../assets/images/icons/Garden-Icon.webp')
                                  "
                                />
                                <p style="font-weight: bold" class="m-0">
                                  Garden:
                                </p>
                                <span v-if="eval(listing.garden)"
                                  >{{ capitalize(listing.garden) }}
                                  {{
                                    listing.gardenArea == "acres"
                                      ? "Acres"
                                      : "Sq. Meters"
                                  }}</span
                                >
                                <span v-else>None</span>
                              </div>
                              <div
                                v-if="
                                  listing.swimmingPool && swimmingPool != 'none'
                                "
                                class="col-md-2 text-center res-margin-imgaes"
                              >
                                <img
                                  class="custome-images-icon res-sm-img"
                                  :src="
                                    require('../assets/images/icons/Swimming-Pool-Icon.webp')
                                  "
                                />
                                <p style="font-weight: bold" class="m-0">
                                  Swimming Pool:
                                </p>
                                <span v-if="listing.swimmingPool != 'No'">{{
                                  capitalize(listing.swimmingPool)
                                }}</span>
                                <span v-else>None</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 mt-4">
                            <div class="mt-5">
                              <div
                                v-if="
                                  listing
                                    ? listing.floorPlan.length
                                    : null ||
                                      (listing
                                        ? listing.epcCertificate
                                          ? listing.epcCertificate.epcDocument
                                              .length
                                          : false
                                        : null)
                                "
                                style="border-radius: 8px 8px 8px 8px"
                                class="
                                  floor-plan
                                  property
                                  wprt-image-video
                                  w50
                                  pro
                                "
                              >
                                <div
                                  v-if="listing.floorPlan.length"
                                  class="row"
                                >
                                  <div
                                    class="col-md-4 mb-5"
                                    v-for="(image, index) in floorPlan"
                                    :key="index"
                                    style="height: 300px; width: 319px"
                                  >
                                    <img
                                      @click="enlargeImage('floorPlan')"
                                      style="
                                        width: 100%;
                                        height: 100%;
                                        object-fit: fill;
                                      "
                                      class="mb-3"
                                      alt="image"
                                      :src="`${image}`"
                                    />
                                  </div>
                                  <div
                                    style="height: 300px; width: 319px"
                                    v-if="listing.epcCertificate"
                                  >
                                    <img
                                      @click="enlargeImage('epcCertificate')"
                                      v-if="
                                        listing.epcCertificate.epcDocument
                                          .length &&
                                        getExtension(
                                          listing.epcCertificate.epcDocument[0]
                                        ).includes('png')
                                      "
                                      style="
                                        width: 100%;
                                        height: 100%;
                                        object-fit: fill;
                                      "
                                      alt="image"
                                      :src="
                                        listing.epcCertificate
                                          ? `${listing.epcCertificate.epcDocument}`
                                          : ''
                                      "
                                    />
                                    <a
                                      v-else-if="
                                        getExtension(
                                          listing.epcCertificate.epcDocument[0]
                                        ).includes('pdf')
                                      "
                                      target="_blank"
                                      :href="`${listing.epcCertificate.epcDocument[0]}`"
                                      >{{
                                        listing.epcCertificate.epcDocument[0]
                                      }}</a
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                <div
                                  style="border-radius: 8px 8px 8px 8px"
                                  class="blog-info details mb-30"
                                >
                                  <h5 class="mb-4 main-description">
                                    About Property
                                  </h5>
                                  <div class="mb-4">
                                    {{
                                      listing.description[0]
                                        ? listing.description[0].value[
                                            Object.keys(
                                              listing.description[0].value
                                            )[0]
                                          ]
                                        : ""
                                    }}
                                  </div>
                                  <h5 class="mb-4">Description</h5>
                                  <span
                                    class="mb-3"
                                    v-for="(
                                      description, index
                                    ) in convertedDescription"
                                    :key="index"
                                  >
                                    <div
                                      class="mb-4"
                                      v-if="
                                        description.value[
                                          Object.keys(description.value)[0]
                                        ] &&
                                        Object.keys(description.value)[0] !==
                                          `mainDescription` &&
                                        (Object.keys(description.value)[0] ==
                                        `tenureDescription`
                                          ? listing.tenure == 'lease'
                                          : true)
                                      "
                                    >
                                      <h3>
                                        {{ Object.keys(description.value)[0] }}:
                                      </h3>
                                      <p style="white-space: break-spaces">
                                        {{
                                          description.value[
                                            Object.keys(description.value)[0]
                                          ]
                                        }}
                                      </p>
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-center mt-4">
                          <div
                            style="
                              width: 100%;
                              height: 5vh;
                              background-color: #f6f6f6;
                            "
                          ></div>
                        </div>
                        <div class="row ml-1">
                          <button
                            style="color: white"
                            class="
                              res-back-button
                              btn btn-yellow
                              listing-button-color
                              px-6
                              mt-3
                              w135
                              pull-left
                              setleft
                            "
                            @click="back()"
                          >
                            Back
                          </button>

                          <button
                            style="color: white"
                            class="
                              res-next-button
                              btn btn-yellow
                              w135
                              listing-button-color
                              res-b-bacck-1
                              mt-3
                              px-6
                              pull-right
                              ml-auto
                              setleft
                              mr-3
                            "
                            @click="update"
                          >
                            <b-spinner small v-if="showSpinner"></b-spinner
                            >{{ "Save" }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </tab-content>
    </form-wizard>
    <b-modal
      v-if="listing"
      size="lg"
      centered
      hide-header
      hide-footer
      ref="imageBox"
      id="modal-1"
    >
      <div v-if="listing.floorPlan && shouldShow == 'floorPlan'">
        <div
          class="d-flex justify-content-center"
          v-for="(image, index) in listing.floorPlan"
          :key="index"
          :style="`height: ${screenHeight / 1.2}px; width: ${
            screenWidth / 2.4
          }px; background-color: grey`"
        >
          <img class="mb-3" alt="image" :src="`${image}`" />
        </div>
      </div>
      <div
        class="d-flex justify-content-center"
        v-if="listing.epcCertificate && shouldShow == 'epcCertificate'"
        :style="`height: ${screenHeight / 1.2}px; width: ${
          screenWidth / 2.4
        }px; background-color: grey`"
      >
        <img
          alt="image"
          :src="
            listing.epcCertificate
              ? `${listing.epcCertificate.epcDocument}`
              : ''
          "
        />
      </div>
    </b-modal>
  </div>
</template>


<script>
import $ from "jquery";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
const simpleListProperty = () =>
  import(
    /* webpackChunkName: "o4OQ2SUeWh" */ "../components/simple-list-property"
  );
import vueDropzone from "vue2-dropzone";
import "../../node_modules/vue2-dropzone/dist/vue2Dropzone.min.css";
import { headers } from "../utils";
import { onlyNumberInput } from "../utils";
import { capitalize, formatter } from "../utils";
import _ from "lodash";

export default {
  components: {
    vueDropzone,
    simpleListProperty,
  },
  middleware: ["authentication"],
  data() {
    return {
      removedList: {
        garageDescription: false,
        tenureDescription: false,
        swimmingPoolDescription: false,
        parkingDescription: false,
        gardenDescription: false,
      },
      enums: {},
      listing: null,
      customRooms: [],
      customRoomDescription: [],
      otherroomDescription: [],
      bathroomDescription: [],
      bedroomDescription: [],
      mainPageDescriptionTitle: "Main Description",
      garageDescriptionTitle: "Garage",
      swimmingPoolDescriptionTitle: "Swimming Pool",
      parkingDescriptionTitle: "Parking",
      gardenDescriptionTitle: "Garden",
      tenureDescriptionTitle: "Tenure",
      shouldShow: false,
      screenHeight: window.innerHeight,
      screenWidth: window.innerWidth,
      shouldShow: "",
      orderChanged: false,
      removing: false,
      switching: false,
      showLaterMessageFloorPlan: false,
      showLaterMessageImages: false,
      showWarningMessage: false,
      showError: false,
      doNotDraft: true,
      focused: false,
      isGardenChecked: false,
      switchTo: false,
      isDescriptionChanged: false,
      county: "",
      inner: "",
      showRequired: false,
      showSpinner: false,
      mainDescription: "",
      garageDescription: "",
      parkingDescription: "",
      tenureDescription: "",
      mainPageDescription: "",
      swimmingPoolDescription: "",
      gardenDescription: "",
      epcRating: "",
      uploadedImages3: [],
      uploadedImages2: [],
      uploadedImages: [],
      isImageUploaded3: true,
      isImageUploaded2: true,
      isImageUploaded: true,
      imagesAdded: false,
      images: [],
      dropOptionsEpc: {
        transformFile: function (file, done) {
          var reader = new FileReader();

          reader.onload = (e) => {
            file.dataURL = e.target.result;
            done(file);
          };
          reader.readAsDataURL(file);
        },
        accept: function (file, done) {
          setTimeout(() => {
            done();
          }, 1000);
        },
        maxFiles: 1,
        addRemoveLinks: true,
        headers: headers().headers,
        url: `${process.env.VUE_APP_BASE_URL}/api/base64`,
      },
      dropOptions: {
        transformFile: function (file, done) {
          var reader = new FileReader();

          reader.onload = (e) => {
            file.dataURL = e.target.result;
            done(file);
          };
          reader.readAsDataURL(file);
        },
        accept: function (file, done) {
          setTimeout(() => {
            done();
          }, 1000);
        },
        acceptedFiles: "image/jpeg,image/png,.mp4,.mkv,.avi",
        addRemoveLinks: true,
        headers: headers().headers,
        url: `${process.env.VUE_APP_BASE_URL}/api/base64`,
      },
      dropOptionsFloorPlan: {
        transformFile: function (file, done) {
          var reader = new FileReader();

          reader.onload = (e) => {
            file.dataURL = e.target.result;
            done(file);
          };
          reader.readAsDataURL(file);
        },
        accept: function (file, done) {
          setTimeout(() => {
            done();
          }, 1000);
        },
        addRemoveLinks: true,
        headers: headers().headers,
        url: `${process.env.VUE_APP_BASE_URL}/api/base64`,
      },
      fileToBeRemoved: null,
      epcFile: [],
      floorPlan: [],
      floorPlans: null,
      epcDescription: "",
      file1: null,
      addressType: "manual",
      propertyTitle: "",
      houseNo: "",
      propertyDescription: "",
      status: "",
      type: "",
      rooms: "",
      price: "",
      area: "",
      street: "",
      city: "",
      postCode: "",
      country: "UK",
      lat: "",
      lng: "",
      bedroom: "",
      bathroom: "",
      otherroom: "",
      features: [],
      basement: "",
      garage: "",
      swimmingPool: "",
      kitchen: "",
      parking: "",
      noOfCars: "",
      parkingType: "",
      garden: "",
      gardenArea: "acres",
      name: "",
      username: "",
      email: "",
      phone: "",
      tenure: "free",
      totalDescription: [],
      customLabel: "",
      customLabelHolder: [],
      convertedDescription: [],
      lookupAddress: "1",
    };
  },
  computed: {
    eval() {
      return eval;
    },
    ...mapGetters({
      authUser: "auth",
      properties: "getUserProperties",
      buyerSeller: "getBuyerSeller",
    }),
  },
  watch: {
    totalDescription() {
      this.convertObject();
    },
    properties() {
      let listing = this.properties.find(
        (list) => list.id == this.$route.params.id
      );
      this.listing = { ...listing };
    },
    listing() {
      this.houseNo = this.listing.houseNo;
      this.county = this.listing.county;
      this.city = this.listing.city;
      this.postCode = this.listing.postCode;
      this.street = this.listing.street;
      this.price = this.formatter(this.listing.price);
      this.type = this.listing.propertyType;
      this.garage = this.listing.garage;
      this.tenure = this.listing.tenure;
      this.swimmingPool = this.listing.swimmingPool;
      this.noOfCars = this.listing.noOfCars;
      this.parkingType = this.listing.parking;
      this.garden = this.listing.garden;
      this.gardenArea = this.listing.gardenArea;
      eval(this.listing.garden)
        ? (this.isGardenChecked = true)
        : (this.isGardenChecked = false);
      this.epcRating = this.listing.epcCertificate
        ? this.listing.epcCertificate.epcRating
        : "";
      if (this.listing.description.length) {
        this.$nextTick(() => {
          let found = this.listing.description.filter(
            (desc) => desc.label == "bedroom"
          );
          let orderArr = Array.from(
            new Set(found.map((f) => parseInt(f.order)))
          ).map((order) => {
            return {
              order: order,
            };
          });
          this.addToDescription("bedroom", this.listing.noOfBedrooms, orderArr);
          this.bedroomDescription = [];
          for (let i = 0; i < found.length; i++) {
            this.bedroomDescription = [
              ...this.bedroomDescription,
              JSON.parse(`{ "${found[i].key}" : {
          "label": "${found[i].label}",
          "order": "${found[i].order}",
          "value": "${_.defaultTo(
            _.get(found[i].value, _.get(found[i], "key", ""), ""),
            ""
          ).replace(/[\n\r]/g, "\\n")}",
          "key": "${found[i].key}"
        }
        }`),
            ];
          }
        });
        this.$nextTick(() => {
          let found = this.listing.description.filter(
            (desc) => desc.label == "bathroom"
          );
          let orderArr = Array.from(
            new Set(found.map((f) => parseInt(f.order)))
          ).map((order) => {
            return {
              order: order,
            };
          });
          this.addToDescription(
            "bathroom",
            this.listing.noOfBathrooms,
            orderArr
          );
          this.bathroomDescription = [];
          for (let i = 0; i < found.length; i++) {
            this.bathroomDescription = [
              ...this.bathroomDescription,
              JSON.parse(`{ "${found[i].key}" : {
          "label": "${found[i].label}",
          "order": "${found[i].order}",
          "value": "${_.defaultTo(
            _.get(found[i].value, _.get(found[i], "key", ""), ""),
            ""
          ).replace(/[\n\r]/g, "\\n")}",
          "key": "${found[i].key}"
        }
        }`),
            ];
          }
        });
        this.$nextTick(() => {
          let found = this.listing.description.filter(
            (desc) => desc.label == "otherroom"
          );
          let orderArr = Array.from(
            new Set(found.map((f) => parseInt(f.order)))
          ).map((order) => {
            return {
              order: order,
            };
          });
          this.addToDescription(
            "otherroom",
            this.listing.noOfOtherRooms,
            orderArr
          );
          this.otherroomDescription = [];
          for (let i = 0; i < found.length; i++) {
            this.otherroomDescription = [
              ...this.otherroomDescription,
              JSON.parse(`{ "${found[i].key}" : {
          "label": "${found[i].label}",
          "order": "${found[i].order}",
          "value": "${_.defaultTo(
            _.get(found[i].value, _.get(found[i], "key", ""), ""),
            ""
          ).replace(/[\n\r]/g, "\\n")}",
          "key": "${found[i].key}"
        }
        }`),
            ];
          }
        });
        this.$nextTick(() => {
          let found = this.listing.description.filter(
            (desc) => desc.label == "custom"
          );
          this.customRoomDescription = [];
          for (let i = 0; i < found.length; i++) {
            this.customRooms = [...this.customRooms, { order: i + 1 }];
            this.customRoomDescription = [
              ...this.customRoomDescription,
              JSON.parse(`{ "${found[i].key}" : {
          "label": "${found[i].label}",
          "order": "${found[i].order}",
          "value": "${_.defaultTo(
            _.get(found[i].value, _.get(found[i], "key", ""), ""),
            ""
          ).replace(/[\n\r]/g, "\\n")}",
          "key": "${found[i].key}"
        }
        }`),
            ];
          }
        });
        this.mainDescription = (() => {
          let found = this.listing.description.find(
            (desc) => desc.label == "main"
          );
          return found.value[Object.keys(found.value)[0]];
        })();
        this.mainPageDescription = (() => {
          let found = this.listing.description.find(
            (desc) => desc.label == "mainSecondary"
          );
          this.mainPageDescriptionTitle = Object.keys(found.value)[0];
          return found.value[Object.keys(found.value)[0]];
        })();
        this.garageDescription = (() => {
          let found = this.listing.description.find(
            (desc) => desc.label == "garageDescription"
          );
          this.$set(this.removedList, "garageDescription", eval(found.removed));
          this.garageDescriptionTitle = Object.keys(found.value)[0];
          return found.value[Object.keys(found.value)[0]];
        })();
        if (this.listing.tenure == "lease") {
          this.tenureDescription = (() => {
            let found = this.listing.description.find(
              (desc) => desc.label == "tenureDescription"
            );
            this.$set(
              this.removedList,
              "tenureDescription",
              eval(found.removed)
            );
            this.tenureDescriptionTitle = Object.keys(found.value)[0];
            return found.value[Object.keys(found.value)[0]];
          })();
        }
        this.swimmingPoolDescription = (() => {
          let found = this.listing.description.find(
            (desc) => desc.label == "swimmingPoolDescription"
          );
          this.$set(
            this.removedList,
            "swimmingPoolDescription",
            eval(found.removed)
          );
          this.swimmingPoolDescriptionTitle = Object.keys(found.value)[0];
          return found.value[Object.keys(found.value)[0]];
        })();
        this.parkingDescription = (() => {
          let found = this.listing.description.find(
            (desc) => desc.label == "parkingDescription"
          );
          this.$set(
            this.removedList,
            "parkingDescription",
            eval(found.removed)
          );
          this.parkingDescriptionTitle = Object.keys(found.value)[0];
          return found.value[Object.keys(found.value)[0]];
        })();
        this.gardenDescription = (() => {
          let found = this.listing.description.find(
            (desc) => desc.label == "gardenDescription"
          );
          this.$set(this.removedList, "gardenDescription", eval(found.removed));
          this.gardenDescriptionTitle = Object.keys(found.value)[0];
          return found.value[Object.keys(found.value)[0]];
        })();
      }
      this.convertObject();
      this.isDescriptionChanged = false;
      this.images = this.listing.images;
      this.floorPlan = this.listing.floorPlan
        ? JSON.parse(this.listing.floorPlan)
        : [];
      this.epcFile = this.listing.epcCertificate
        ? this.listing.epcCertificate.epcDocument
          ? this.listing.epcCertificate.epcDocument
          : []
        : [];
    },
    buyerSeller(val) {
      this.$store
        .dispatch("getConfigurationsByKey", "referralSlabs")
        .then((res) => {
          if (res.data.success) {
            this.referralSlabs = res.data.data;
            this.showRedeem =
              val.referralPoints >= this.referralSlabs.val.slab1.range;
          }
        });
    },
    isGardenChecked(val) {
      if (!val) this.garden = "0";
    },
    lookupAddress(val) {
      this.street = val["thoroughfare"];
      this.city = val["town_or_city"];
      this.county = val["county"];
      this.addressType = "lookup";
      this.houseNo = val["building_number"];
      this.switchTo = true;
    },
    postCode(val) {
      if (val.length >= 3)
        this.$store.dispatch("addressLookup", {
          postCode: val,
          houseNo: "",
        });
    },
  },
  validations: {
    epcDescription: {
      required,
    },
    epcFile: {
      required,
    },
    propertyTitle: {
      required,
    },
    propertyDescription: {
      required,
    },
    status: {
      required,
    },
    type: {
      required,
    },
    rooms: {
      required,
    },
    price: {
      required,
    },
    area: {
      required,
    },
    houseNo: {
      required,
    },
    street: {
      required,
    },
    city: {
      required,
    },
    postCode: {
      required,
    },
    lat: {
      required,
    },
    lng: {
      required,
    },
    name: {
      required,
    },
    username: {
      required,
    },
    email: {
      required,
    },
    phone: {
      required,
    },
    addressType: {
      required,
    },
    tenure: {
      required,
    },
    floorPlan: {
      required,
    },
    epcRating: {
      required,
    },
    mainDescription: {
      required,
    },
    images: {
      required,
    },
  },
  methods: {
    addPhoto() {
      document.querySelectorAll(".vue-dropzone")[0].click();
    },
    addPhotoFloorPlan() {
      document.querySelectorAll(".vue-dropzone")[1].click();
    },
    addPhotoEpc() {
      document.querySelectorAll(".vue-dropzone")[2].click();
    },
    setDescriptionTitle() {
      this.tenureDescriptionTitle =
        "Tenure: " + this.capitalize(this.tenure) + "hold";
    },
    detectChange(items) {
      let check = true;
      for (let i = 0; i < items.length; i++) {
        if (items[i] == "epcCertificate") {
          if (this.listing.epcCertificate) {
            if (this.epcRating != this.listing.epcCertificate.epcRating)
              check = false;
            this.listing.epcCertificate.epcDocument.forEach((image) => {
              if (!this.epcFile[0]) check = false;
              else {
                if (!this.epcFile[0].includes(image)) check = false;
              }
            });
            this.epcFile.forEach((image) => {
              if (!this.listing.epcCertificate.epcDocument[0]) check = false;
              else {
                if (!this.listing.epcCertificate.epcDocument[0].includes(image))
                  check = false;
              }
            });
          } else check = false;
        } else if (items[i] == "floorPlan") {
          this[items[i]].forEach((image) => {
            if (!this.listing.floorPlan.includes(image)) check = false;
          });
        } else if (items[i] == "images") {
          this[items[i]].forEach((image) => {
            if (!this.listing.images.includes(image)) check = false;
          });
        } else {
          if (
            (items[i] == "swimmingPool" ||
              items[i] == "parking" ||
              items[i] == "garden") &&
            check
          ) {
            check = this[items[i]] == this.listing[this.enums[items[i]]];
            continue;
          }
          if (this[items[i]] != this.listing[this.enums[items[i]]]) {
            check = false;
          }
        }
      }
      return check;
    },
    removeBedBathOther(label, title, order) {
      if (label == "bedroom") {
        let sliced = this.bedroomDescription.filter((desc) => {
          return Object.keys(desc)[0] !== title;
        });
        this.bedroomDescription = [];
        let sliced2 = this.bedroom.filter((room) => room.order !== order);
        this.bedroom = [];
        for (let i = 0; i < sliced.length; i++)
          this.$set(this.bedroomDescription, `${i}`, sliced[i]);
        for (let i = 0; i < sliced2.length; i++)
          this.$set(this.bedroom, `${i}`, sliced2[i]);
      } else if (label == "bathroom") {
        let sliced = this.bathroomDescription.filter((desc) => {
          return Object.keys(desc)[0] !== title;
        });
        this.bathroomDescription = [];
        let sliced2 = this.bathroom.filter((room) => room.order !== order);
        this.bathroom = [];
        for (let i = 0; i < sliced.length; i++)
          this.$set(this.bathroomDescription, `${i}`, sliced[i]);
        for (let i = 0; i < sliced2.length; i++)
          this.$set(this.bathroom, `${i}`, sliced2[i]);
      } else if (label == "otherroom") {
        let sliced = this.otherroomDescription.filter((desc) => {
          return Object.keys(desc)[0] !== title;
        });
        this.otherroomDescription = [];
        let sliced2 = this.otherroom.filter((room) => room.order !== order);
        this.otherroom = [];
        for (let i = 0; i < sliced.length; i++)
          this.$set(this.otherroomDescription, `${i}`, sliced[i]);
        for (let i = 0; i < sliced2.length; i++)
          this.$set(this.otherroom, `${i}`, sliced2[i]);
      }
    },
    removeOtherThanBathBedOther(label) {
      this.$set(this.removedList, label, true);
    },
    convertToInt(value) {
      return parseInt(value) || 0;
    },
    formatter: formatter,
    orderedCustomBoxes(num) {
      let arr = [];
      for (let i = 1; i <= num; i++) {
        arr = [...arr, i];
      }
      return arr.map((a) => {
        return {
          order: a,
        };
      });
    },
    findBedroomDescription(order) {
      let found = this.bedroomDescription.find(
        (desc) => desc[Object.keys(desc)[0]].order == order
      );
      return found ? found[Object.keys(found)[0]] : {};
    },
    findBathroomDescription(order) {
      let found = this.bathroomDescription.find(
        (desc) => desc[Object.keys(desc)[0]].order == order
      );
      return found ? found[Object.keys(found)[0]] : {};
    },
    findOtherroomDescription(order) {
      let found = this.otherroomDescription.find(
        (desc) => desc[Object.keys(desc)[0]].order == order
      );
      return found ? found[Object.keys(found)[0]] : {};
    },
    findCustomroomDescription(order) {
      let found = this.customRoomDescription.find(
        (desc) => desc[Object.keys(desc)[0]].order == order
      );
      if (found) return found[Object.keys(found)[0]];
      else return {};
    },
    checkLength(event) {
      if (event.target.value.length > 7) event.preventDefault();
    },
    enlargeImage(comp) {
      this.shouldShow = comp;
      this.$refs.imageBox.show();
    },
    addCommas(event) {
      this.price = this.formatter(
        event.target.value.toString().replace(/,/g, "")
      );
    },
    checkIfCustomBox(label) {
      return (
        !label.includes("bedroom") &&
        !label.includes("bathroom") &&
        !label.includes("otherroom")
      );
    },
    getExtension(str) {
      var re = /(?:\.([^.]+))?$/;
      return re.exec(str);
    },
    onError(file, message, xhr) {
      this.$refs.dropzone.removeFile(file);
      this.showError = true;
    },
    scrollToMain() {
      document
        .querySelector(".main-description")
        .scrollIntoView({ behavior: "smooth" });
    },
    sliceString(str) {
      if (str) {
        if (str.length > 280) {
          this.shouldShow = true;
          return str.slice(0, 280);
        } else return str;
      } else return "";
    },
    isCustomLabel(description) {
      return (
        description.label != "bedroom" &&
        description.label != "bathroom" &&
        description.label != "otherroom"
      );
    },
    removeDiv() {
      if ($(window).width() <= 700) {
        $(".remove").removeClass("mt-5");
        $(".remove").removeClass("m5");
      } else if ($(window).width() > 700) {
        $(".remove").addClass("mt-5");
        $(".remove").addClass("m5");
      }
    },
    capitalize: capitalize,
    dropping() {
      if (this.fileToBeRemoved)
        this.$refs.dropzone3.removeFile(this.fileToBeRemoved);
    },
    inputLimitClipboard() {
      this.mainDescription = this.mainDescription.slice(0, 250);
    },
    sliceString(str) {
      if (str) {
        if (str.length > 394) {
          this.shouldShow = true;
          return str.slice(0, 385);
        } else return str;
      } else return "";
    },
    inputLimit(event) {
      if (this.mainDescription.length > 250) event.preventDefault();
    },
    checkMainDesc(event) {},
    inputCheck(event) {
      onlyNumberInput(event);
    },
    back() {
      this.$refs.prevButton.$el.click();
    },
    nextForDescription() {
      this.removeEmptyBoxes();
      document
        .querySelector(".formWizard")
        .scrollIntoView({ behavior: "smooth" });
      this.$refs.nextButton.$el.click();
    },
    next() {
      this.removeEmptyBoxes();
      document
        .querySelector(".formWizard")
        .scrollIntoView({ behavior: "smooth" });
      this.$refs.nextButton.$el.click();
    },
    addEpcCertificate(event) {
      let epcFile = event.target.files[0];
      var fileReader = new FileReader();
      var base64;
      fileReader.onload = (fileLoadedEvent) => {
        base64 = fileLoadedEvent.target.result;
        this.$store.dispatch("postImage", { image: base64 }).then((res) => {
          this.epcFile = res.data.data;
        });
      };
      fileReader.readAsDataURL(epcFile);
    },
    fileRemoved3(file, error, xhr) {
      if (this.uploadedImages3.length) {
        let removedImage = _.find(this.uploadedImages3, (image) => {
          return image.id == file.upload.uuid;
        });
        this.epcFile = _.filter(this.epcFile, (image) => {
          return image !== removedImage.url;
        });
        this.uploadedImages3 = _.filter(this.uploadedImages3, (image) => {
          return image.url !== removedImage.url;
        });
      } else {
        this.epcFile.forEach((image, index) => {
          if (image == file.url) {
            let images = [...this.epcFile];
            images.splice(index, 1);
            this.epcFile = [...images];
          }
        });
      }
    },
    fileRemoved2(file, error, xhr) {
      if (this.uploadedImages2.length) {
        let removedImage = _.find(this.uploadedImages2, (image) => {
          return image.id == file.upload.uuid;
        });
        this.floorPlan = _.filter(this.floorPlan, (image) => {
          return image !== removedImage.url;
        });
        this.uploadedImages2 = _.filter(this.uploadedImages2, (image) => {
          return image.url !== removedImage.url;
        });
      } else {
        this.floorPlan.forEach((image, index) => {
          if (image == file.url) {
            let images = [...this.floorPlan];
            images.splice(index, 1);
            this.floorPlan = [...images];
          }
        });
      }
    },
    fileRemoved(file, error, xhr) {
      if (this.uploadedImages.length) {
        let removedImage = _.find(this.uploadedImages, (image) => {
          return image.id == file.upload?.uuid;
        });
        this.images = _.filter(this.images, (image) => {
          return image !== removedImage?.url;
        });
        this.uploadedImages = _.filter(this.uploadedImages, (image) => {
          return image.url !== removedImage?.url;
        });
      } else {
        this.images.forEach((image, index) => {
          if (image == file.url) {
            let images = [...this.images];
            images.splice(index, 1);
            this.images = [...images];
          }
        });
      }
    },
    dropzoneMountedEpc() {
      this.$nextTick(() => {
        if (this.epcFile) {
          this.epcFile.forEach((image, index) => {
            var file = {
              size: 123,
              name: `${index}`,
              type: "image/png",
              url: image,
            };
            this.$refs.dropzone3.manuallyAddFile(file, `${image}`);
          });
        }
      });
    },
    dropzoneMountedFloorPlan() {
      this.$nextTick(() => {
        if (this.floorPlan) {
          this.floorPlan.forEach((image, index) => {
            var file = {
              size: 123,
              name: `${index}`,
              type: "image/png",
              url: image,
            };
            this.$refs.dropzone2.manuallyAddFile(file, `${image}`);
          });
        }
      });
    },
    dropzoneMounted() {
      document.querySelector("#drop1").classList.add("sortable");
      $(".sortable").sortable({
        update: () => {
          let orderChangeImages = [...this.images];
          for (
            let i = 0;
            i < document.querySelectorAll(".dz-image img").length;
            i++
          ) {
            let currentIndex =
              document.querySelectorAll(".dz-image")[i].dataset.imageId;
            orderChangeImages[i] = this.images[currentIndex - 1];
          }
          this.images.splice(0, this.images.length, ...orderChangeImages);
          this.orderChanged = true;
        },
      });
      this.$nextTick(() => {
        this.images.forEach((image, index) => {
          var file = {
            size: 123,
            name: `${index}`,
            type: "image/png",
            url: image,
          };
          this.$refs.dropzone.manuallyAddFile(file, `${image}`);
          document.querySelectorAll(".dz-image")[index].dataset.imageId =
            index + 1;
        });
      });
    },
    imageUploadComplete3(file, response) {
      this.fileToBeRemoved = file;
      this.epcFile = [response.data];
      this.isImageUploaded3 = true;
      this.uploadedImages3 = [{ id: file.upload.uuid, url: response.data }];
    },
    sendImages3(file, xhr, formData) {
      this.isImageUploaded3 = false;
      formData.append("image", file.dataURL);
    },
    imageUploadComplete2(file, response) {
      this.floorPlan = [...this.floorPlan, response.data];
      this.isImageUploaded2 = true;
      this.uploadedImages2 = [
        ...this.uploadedImages2,
        { id: file.upload.uuid, url: response.data },
      ];
    },
    sendImages2(file, xhr, formData) {
      this.isImageUploaded2 = false;
      formData.append("image", file.dataURL);
    },
    imageUploadComplete(file, response) {
      $(".sortable").sortable("enable");
      this.images = [...this.images, response.data];
      this.$nextTick(() => {
        document.querySelectorAll(".dz-image")[
          this.images.length - 1
        ].dataset.imageId = this.images.length;
      });
      this.isImageUploaded = true;
      this.uploadedImages = [
        ...this.uploadedImages,
        { id: file.upload.uuid, url: response.data },
      ];
    },
    sendImages(file, xhr, formData) {
      this.isImageUploaded = false;
      formData.append("image", file.dataURL);
      $(".sortable").sortable("disable");
    },
    async check2() {
      if (this.detectChange(["images"])) return true;
      return await this.updatePartial();
    },
    async checkImage() {
      if (this.detectChange(["floorPlan"])) return true;
      return await this.updatePartial();
    },
    async checkEpc() {
      if (this.detectChange(["epcCertificate"])) return true;
      return await this.updatePartial();
    },
    async checkDescription() {
      this.convertObject();
      if (this.$v.mainDescription.$invalid) {
        this.$v.mainDescription.$touch();
        return false;
      } else {
        if (!this.isDescriptionChanged) return true;
        else {
          this.isDescriptionChanged = false;
          return await this.updatePartial();
        }
      }
    },
    convertObject() {
      this.convertedDescription = [];
      this.convertedDescription = JSON.parse(`[
        {
          "value": { "mainDescription": "${_.defaultTo(
            this.mainDescription,
            ""
          ).replace(/[\n\r]/g, "\\n")}" },
          "label": "main"
        },
        {
          "value": { "${_.defaultTo(this.mainPageDescriptionTitle, "").replace(
            /[\n\r]/g,
            "\\n"
          )}": "${_.defaultTo(this.mainPageDescription, "").replace(
        /[\n\r]/g,
        "\\n"
      )}" },
          "label": "mainSecondary"
        },
        {
          "value": { "${_.defaultTo(this.tenureDescriptionTitle, "").replace(
            /[\n\r]/g,
            "\\n"
          )}": "${_.defaultTo(this.tenureDescription, "").replace(
        /[\n\r]/g,
        "\\n"
      )}" },
          "label": "tenureDescription",
          "removed": "${this.removedList["tenureDescription"]}"
        }]`);
      this.bedroomDescription.forEach((desc) => {
        this.convertedDescription = [
          ...this.convertedDescription,
          JSON.parse(`{
           "value": { "${desc[Object.keys(desc)[0]].key}": "${desc[
            Object.keys(desc)[0]
          ].value.replace(/[\n\r]/g, "\\n")}" },
          "label": "bedroom",
          "order": "${desc[Object.keys(desc)[0]].order}",
          "key": "${desc[Object.keys(desc)[0]].key}"
        }`),
        ];
      });
      this.bathroomDescription.forEach((desc) => {
        this.convertedDescription = [
          ...this.convertedDescription,
          JSON.parse(`{
           "value": { "${desc[Object.keys(desc)[0]].key}": "${desc[
            Object.keys(desc)[0]
          ].value.replace(/[\n\r]/g, "\\n")}" },
          "label": "bathroom",
          "order": "${desc[Object.keys(desc)[0]].order}",
          "key": "${desc[Object.keys(desc)[0]].key}"
        }`),
        ];
      });
      this.otherroomDescription.forEach((desc) => {
        this.convertedDescription = [
          ...this.convertedDescription,
          JSON.parse(`{
           "value": { "${desc[Object.keys(desc)[0]].key}": "${desc[
            Object.keys(desc)[0]
          ].value.replace(/[\n\r]/g, "\\n")}" },
          "label": "otherroom",
          "order": "${desc[Object.keys(desc)[0]].order}",
          "key": "${desc[Object.keys(desc)[0]].key}"
        }`),
        ];
      });
      let joinString = JSON.parse(`[{
          "value": { "${_.defaultTo(this.garageDescriptionTitle, "").replace(
            /[\n\r]/g,
            "\\n"
          )}": "${_.defaultTo(this.garageDescription, "").replace(
        /[\n\r]/g,
        "\\n"
      )}" },
          "label": "garageDescription",
          "removed": "${this.removedList["garageDescription"]}"
        },
        {
          "value": { "${_.defaultTo(
            this.swimmingPoolDescriptionTitle,
            ""
          ).replace(/[\n\r]/g, "\\n")}": "${_.defaultTo(
        this.swimmingPoolDescription,
        ""
      ).replace(/[\n\r]/g, "\\n")}" },
          "label": "swimmingPoolDescription",
          "removed": "${this.removedList["swimmingPoolDescription"]}"
        },
        {
          "value": { "${_.defaultTo(this.parkingDescriptionTitle, "").replace(
            /[\n\r]/g,
            "\\n"
          )}": "${_.defaultTo(this.parkingDescription, "").replace(
        /[\n\r]/g,
        "\\n"
      )}" },
          "label": "parkingDescription",
          "removed": "${this.removedList["parkingDescription"]}"
        },
        {
           "value": { "${_.defaultTo(this.gardenDescriptionTitle, "").replace(
             /[\n\r]/g,
             "\\n"
           )}": "${_.defaultTo(this.gardenDescription, "").replace(
        /[\n\r]/g,
        "\\n"
      )}" },
          "label": "gardenDescription",
          "removed": "${this.removedList["gardenDescription"]}"
        }]`);
      joinString.forEach((desc) => {
        this.convertedDescription = [...this.convertedDescription, desc];
      });
      this.customRoomDescription.forEach((desc) => {
        this.convertedDescription = [
          ...this.convertedDescription,
          JSON.parse(`{
           "value": { "${desc[Object.keys(desc)[0]].key}": "${desc[
            Object.keys(desc)[0]
          ].value.replace(/[\n\r]/g, "\\n")}" },
          "label": "custom",
          "order": "${desc[Object.keys(desc)[0]].order}",
          "key": "${desc[Object.keys(desc)[0]].key}"
        }`),
        ];
      });
    },
    addToDescription(label, count = 0, orderArr) {
      if (label == "bedroom") {
        if (orderArr && _.get(orderArr, "length", false)) {
          this.bedroom = orderArr;
        } else {
          let tempBedroom = [...this.bedroom];
          for (let i = 1; i <= count; i++) {
            tempBedroom = [...tempBedroom, { order: i }];
          }
          this.bedroom = Array.from(
            new Set(tempBedroom.map((room) => room.order))
          ).map((room) => {
            return {
              order: room,
            };
          });
          if (this.bedroom.length > count) {
            this.bedroom = this.bedroom.splice(0, count);
          }
        }
        let temp = [...this.bedroomDescription];
        this.bedroomDescription = [];
        for (let i = 0; i < this.bedroom.length; i++) {
          let found = temp.find((desc) => {
            return desc[Object.keys(desc)[0]].order == i + 1;
          });
          if (found) {
            temp = [...temp, found];
            continue;
          }
          temp = [
            ...temp,
            JSON.parse(`{ "Bedroom ${i + 1}" : {
          "label": "bedroom",
          "order": "${i + 1}",
          "value": "",
          "key": "Bedroom ${i + 1}"
        }
        }`),
          ];
        }
        this.bedroomDescription = temp;
      } else if (label == "bathroom") {
        if (orderArr && _.get(orderArr, "length", false)) {
          this.bathroom = orderArr;
        } else {
          let tempBathroom = [...this.bathroom];
          for (let i = 1; i <= count; i++) {
            tempBathroom = [...tempBathroom, { order: i }];
          }
          this.bathroom = Array.from(
            new Set(tempBathroom.map((room) => room.order))
          ).map((room) => {
            return {
              order: room,
            };
          });
          if (this.bathroom.length > count) {
            this.bathroom = this.bathroom.splice(0, count);
          }
        }
        let temp = [...this.bathroomDescription];
        this.bathroomDescription = [];
        for (let i = 0; i < this.bathroom.length; i++) {
          let found = temp.find((desc) => {
            return desc[Object.keys(desc)[0]].order == i + 1;
          });
          if (found) {
            temp = [...temp, found];
            continue;
          }
          temp = [
            ...temp,
            JSON.parse(`{ "Bathroom ${i + 1}" : {
          "label": "bathroom",
          "order": "${i + 1}",
          "value": "",
          "key": "Bathroom ${i + 1}"
        }
        }`),
          ];
        }
        this.bathroomDescription = temp;
      } else if (label == "otherroom") {
        if (orderArr && _.get(orderArr, "length", false)) {
          this.otherroom = orderArr;
        } else {
          let tempOtherRoom = [...this.otherroom];
          for (let i = 1; i <= count; i++) {
            tempOtherRoom = [...tempOtherRoom, { order: i }];
          }
          this.otherroom = Array.from(
            new Set(tempOtherRoom.map((room) => room.order))
          ).map((room) => {
            return {
              order: room,
            };
          });
          if (this.otherroom.length > count) {
            this.otherroom = this.otherroom.splice(0, count);
          }
        }
        let temp = [...this.otherroomDescription];
        this.otherroomDescription = [];
        for (let i = 0; i < this.otherroom.length; i++) {
          let found = temp.find((desc) => {
            return desc[Object.keys(desc)[0]].order == i + 1;
          });
          if (found) {
            temp = [...temp, found];
            continue;
          }
          temp = [
            ...temp,
            JSON.parse(`{ "Other Room ${i + 1}" : {
          "label": "otherroom",
          "order": "${i + 1}",
          "value": "",
          "key": "Other Room ${i + 1}"
        }
        }`),
          ];
        }
        this.otherroomDescription = temp;
      } else if (label == "custom") {
        this.customRooms = [
          ...this.customRooms,
          { order: this.customRooms.length + 1 },
        ];
        let temp = [...this.customRoomDescription];
        this.customRoomDescription = [];
        for (let i = 0; i < this.customRooms.length; i++) {
          let found = temp.find((desc) => {
            return desc[Object.keys(desc)[0]].order == i + 1;
          });
          if (found) {
            this.customRoomDescription = [...this.customRoomDescription, found];
            continue;
          }
          this.customRoomDescription = [
            ...this.customRoomDescription,
            JSON.parse(`{ "" : {
          "label": "custom",
          "order": "${this.customRooms[i].order}",
          "value": "",
          "key": "Add a title for this room"
        }
        }`),
          ];
        }
      }
    },
    addCustomLabel() {
      this.totalDescription = [
        ...this.totalDescription,
        {
          label: "",
          value: 1,
          isCustom: true,
        },
      ];
    },
    async check3() {
      if (
        this.$v.houseNo.$invalid ||
        this.$v.street.$invalid ||
        this.$v.city.$invalid ||
        this.$v.postCode.$invalid
      ) {
        if (this.$v.houseNo.$invalid) this.$v.houseNo.$touch();
        if (this.$v.street.$invalid) this.$v.street.$touch();
        if (this.$v.city.$invalid) this.$v.city.$touch();
        if (this.$v.postCode.$invalid) this.$v.postCode.$touch();
        return false;
      } else return true;
    },
    async check4() {
      this.$v.type.$touch();
      if (
        this.$v.price.$invalid ||
        this.$v.type.$invalid ||
        this.$v.tenure.$invalid
      ) {
        if (this.$v.tenure.$invalid) this.$v.tenure.$touch();
        if (this.$v.type.$invalid) this.$v.type.$touch();
        if (this.$v.price.$invalid) this.$v.price.$touch();
      } else {
        if (
          this.detectChange([
            "price",
            "type",
            "bedroom",
            "bathroom",
            "otherroom",
            "garage",
            "tenure",
            "swimmingPool",
            "parkingType",
            "garden",
            "noOfCars",
          ])
        )
          return true;
        else {
          return await this.updatePartial();
        }
      }
    },
    removeCustomBox(label, order) {
      let sliced = this.customRoomDescription.filter((desc) => {
        return desc[Object.keys(desc)[0]].order !== order;
      });
      this.customRoomDescription = [];
      for (let i = 0; i < sliced.length; i++)
        this.$set(this.customRoomDescription, `${i}`, sliced[i]);
      let notRemoved = this.customRooms.filter((c) => {
        return c.order != order;
      });
      this.customRooms = [];
      for (let i = 0; i < notRemoved.length; i++) {
        this.$set(this.customRooms, `${i}`, notRemoved[i]);
      }
      this.convertedDescription = this.convertedDescription.filter((desc) => {
        return (
          desc.label !== "custom" ||
          (desc.label == "custom" ? desc.order != order : true)
        );
      });
    },
    removeEmptyBoxes() {
      let sliced = this.totalDescription.filter((desc) => {
        return desc.label;
      });
      this.totalDescription = [];
      for (let i = 0; i < sliced.length; i++)
        this.$set(this.totalDescription, `${i}`, sliced[i]);
      this.removing = true;
    },
    async updatePartial() {
      this.convertObject();
      this.showSpinner = true;
      const data = {
        sellerId: this.authUser.buyer_seller_id,
        addressType: this.addressType,
        description: this.convertedDescription,
        status: this.status,
        propertyType: this.type,
        price: parseInt(this.price.replace(/,/g, "")),
        houseNo: this.houseNo,
        street: this.street,
        city: this.city,
        postCode: this.postCode,
        country: this.country,
        noOfBedrooms: this.bedroom.length,
        noOfBathrooms: this.bathroom.length,
        noOfOtherRooms: this.otherroom.length,
        basement: this.basement ? "Yes" : "No",
        garage: this.garage,
        swimmingPool: this.swimmingPool,
        parking: this.parkingType,
        noOfCars: this.noOfCars,
        garden: this.garden,
        gardenArea: this.gardenArea,
        images: this.images,
        epcCertificate: {
          epcRating: this.epcRating,
          epcDocument: this.epcFile,
        },
        tenure: this.tenure,
        county: this.county,
        createdBy: this.authUser.buyer_seller_id,
        floorPlan: JSON.stringify(this.floorPlan),
      };
      return await this.$store
        .dispatch("updateProperty", {
          id: this.$route.params.id,
          data: data,
        })
        .then((res) => {
          this.showSpinner = false;
          if (res == true) {
            this.$store.dispatch("getUserProperties");
            return true;
          } else return false;
        });
    },
    async update() {
      this.showSpinner = true;
      const data = {
        sellerId: this.authUser.buyer_seller_id,
        addressType: this.addressType,
        description: this.convertedDescription,
        status: this.status,
        propertyType: this.type,
        price: parseInt(this.price.replace(/,/g, "")),
        houseNo: this.houseNo,
        street: this.street,
        city: this.city,
        postCode: this.postCode,
        country: this.country,
        noOfBedrooms: this.bedroom.length,
        noOfBathrooms: this.bathroom.length,
        noOfOtherRooms: this.otherroom.length,
        basement: this.basement ? "Yes" : "No",
        garage: this.garage,
        swimmingPool: this.swimmingPool,
        parking: this.parkingType,
        noOfCars: this.noOfCars,
        garden: this.garden,
        gardenArea: this.gardenArea,
        images: this.images,
        epcCertificate: {
          epcRating: this.epcRating,
          epcDocument: this.epcFile,
        },
        tenure: this.tenure,
        county: this.county,
        createdBy: this.authUser.buyer_seller_id,
        floorPlan: JSON.stringify(this.floorPlan),
      };
      this.$store
        .dispatch("updateProperty", {
          id: this.$route.params.id,
          data: data,
        })
        .then(async (res) => {
          if (res == true) {
            this.$store.commit("setMessage", "Property Updated!");
            await this.$store.dispatch("getUserProperties");
            this.$router.push(`/property-details/${this.$route.params.id}`);
          }
        });
    },
  },
  created() {
    if (!this.properties.length) {
      this.$store.dispatch("getUserProperties").then((res) => {
        if (res == true) {
          let listing = this.properties.find(
            (list) => list.id == this.$route.params.id
          );
          this.listing = { ...listing };
        }
      });
    } else {
      let listing = this.properties.find(
        (list) => list.id == this.$route.params.id
      );
      this.listing = { ...listing };
    }
  },
  async mounted() {
    this.removeDiv();
    this.$nextTick(() => {
      this.$refs.formWizard.activateAll();
    });
  },
};
</script>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>

<style scoped>
.button-link {
  background: transparent;
  border: none;
  color: blue;
}

@media (min-width: 300px) and (max-width: 700px) {
  .w135 {
    width: 80px;
  }
  .res-sm-img {
    width: 22px !important;
    height: 22px !important;
  }

  .res-remove-paddings-multi {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .width-fix12 {
    width: 100% !important;
  }
  .res-pl-0 {
    padding-left: 0px !important;
  }
  .res-float-add {
    float: none !important;
    padding-bottom: 38px !important;
  }

  .res-margin-imgaes {
    margin-bottom: 1.5rem !important;
  }

  .font-set {
    font-family: "Lato", sans-serif !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    color: black;
    font-style: normal;
  }

  .res-ml-54 {
    margin-left: 54px !important;
  }
  .res-photo-pad {
    padding-left: 15px !important;
    padding-right: 0px !important;
  }

  .d-noneneo {
    display: none !important;
  }
  .res-parkking {
    text-align: left !important;
    padding: 10px !important;
  }
  .ress-mar-right {
    margin-right: 10% !important;
  }

  div >>> .wizard-progress-with-circle {
    display: none !important;
  }

  div >>> .wizard-nav-pills {
    display: none !important;
  }
  .setleft {
    padding-left: 26px !important;
  }
  .priceicon {
    position: absolute;
    display: block;
    transform: translate(0, -50%);
    top: 54% !important;
    pointer-events: none;
    width: 25px;
    text-align: center;
    font-style: normal;
  }
  .paddingset {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  div >>> .h0 {
    height: 300px !important;
  }

  .premove {
    margin: 0 !important;
    padding: 0 !important;
  }

  div >>> .h154 {
    height: 220px !important;
  }
}

.px-6 {
  padding-right: 4rem;
  padding-left: 4rem;
}
div >>> .vue-form-wizard * {
  font-style: normal;
}
.px-6 {
  padding-right: 4rem;
  padding-left: 4rem;
}

div >>> .wizard-icon {
  /* width: 69px; */
  width: 71px;
}
div >>> .vue-form-wizard.md .wizard-icon-circle {
  outline: none !important;
  width: 73px !important;
  height: 73px !important;
  font-size: 24px;
}

div >>> #modal-1___BV_modal_content_ {
  background: transparent;
  border: none;
}

div >>> #modal-1___BV_modal_body_ {
  display: flex;
  justify-content: center;
}

div >>> .wizard-icon-container {
  border-radius: 50% !important;
  top: 13px;
  position: relative;
  background-color: rgb(84, 143, 77);
  left: 0px;
  color: back;
  /* height: 70px; */
  height: 73px;
  top: -2px;
  border-radius: 51% !important;
}
div >>> .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: grey;
}
.input-icon {
  position: relative;
}

.pakring-set {
  height: 50px;
  border: 1px solid rgb(221, 221, 221) !important;
  text-align: center;
  border-radius: 0 !important;
  margin-right: 13px;
  margin-left: 4px;
}

.input-icon > i {
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 69%;
  pointer-events: none;
  width: 25px;
  text-align: center;
  font-style: normal;
}

.input-icon > input {
  padding-left: 25px;
  padding-right: 0;
}

.input-icon-right > i {
  right: 0;
}

.input-icon-right > input {
  padding-left: 0;
  padding-right: 25px;
  text-align: right;
}

.color-black {
  color: black !important;
}

@media (min-width: 700px) and (max-width: 4146px) {
  .span-inline-box {
    width: 300px !important;
  }
  .res-disply-big-hide {
    display: none !important;
  }
  .re-b-g-save {
    margin-right: 3% !important;
  }
}

@media (min-width: 300px) and (max-width: 700px) {
  .w-30 {
    width: 50% !important;
    margin-bottom: 0px !important;
  }

  .r-float {
    float: right;
  }

  .res-display-sm-hide {
    display: none !important;
  }
  .res-back-button {
    width: 44% !important;
    margin-right: 17px !important;
  }
  .res-next-button {
    color: white;
    width: 44% !important;

    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .res-sm-img {
    width: 22% !important;
    height: 22px !important;
  }

  .w-40 {
    width: 55% !important;
    margin-bottom: 0px !important;
  }
  .m-set {
    margin-left: 10px !important;
    margin-top: 1px !important;
  }
  .paymentstyle {
    margin-left: 40px !important;
    width: 80% !important;
  }

  .buttonpay {
    margin-left: 100px !important;
  }

  .res-d-none {
    display: none !important;
  }

  div >>> .stripe-card {
    width: 297px !important;
    border: 1px solid grey;
    border-radius: 1px;
    padding: 5px;
  }
}

@media (min-width: 400px) and (max-width: 700px) {
  .buttonpay {
    margin-left: 150px !important;
  }
  .paymentstyle {
    margin-left: 53px !important;
    width: 80% !important;
  }

  .for-res button {
    width: 116px;
  }
  .responsive-line {
    width: 130px;
    margin-left: 50px;
  }
  .listing-button-color {
    margin-left: 0px !important;
  }
  .for-inline-button {
    justify-content: center;
  }
  .input-icon {
    margin-bottom: 6px !important;
  }
  .dropdown {
    margin-bottom: 15px !important;
  }
}

.center {
  background-color: red;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}

.scrolls {
  height: 635px;
  overflow: auto;
}

.error {
  color: red;
}

.fa-check {
  color: green;
}

.hover-disabled {
  cursor: not-allowed;
}

div >>> .stripe-card {
  /* width: 350px; */
  width: 100%;
  border: 1px solid grey;
  border-radius: 1px;
  padding: 5px;
}

.btn-outline-info2 {
  color: #fff;
  border-color: #272b32;
  background-color: #272b32;
}

.btn-outline-info {
  color: #272b32;
  border-color: #272b32;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #272b32;
  border-color: #272b32;
}

.packs {
  color: white;
  height: auto;
}
.stripe-card {
  width: 300px;
  border: 1px solid grey;
}
.stripe-card.complete {
  border-color: green;
}
.w-30 {
  width: 30%;
}
.paymentstyle {
  margin-top: 5px;
}
.buttonpay {
  background: rgb(84, 143, 77);
  width: 132px;
  color: white;
  border-color: rgb(84, 143, 77);
}

.span-color {
  color: rgb(84, 143, 77);
  font-weight: bold;
  font-size: 16px;
}
.pre-listing {
  font-size: 16px;
  font-weight: 500;
  margin: 0px !important;
}
.btn-payment {
  color: white;
  float: right;
  background: #548f4d !important;
}
.btn-paynow {
  color: white;
  background: #548f4d !important;
  padding: 18px;
}

.addButtonPro {
  padding-right: 30px;
  padding-left: 30px;
  height: 50px;
  padding-top: 5px;
}
.popover {
  max-width: 443px;
}

@media (max-width: 430px) {
  .for-res {
    width: 85px;
  }
  div >>> .dz-started {
    text-align: center !important;
  }
  .for-res button {
    width: 116px !important;
  }
  .for-res-left {
    width: 100px !important;
    margin-left: 46px !important;
  }
  .responsive-line {
    width: 130px !important;
    margin-left: 50px !important;
  }
  .listing-button-color {
    margin-left: 0px !important;
  }
  .for-inline-button {
    justify-content: center !important;
  }
  .input-icon {
    margin-bottom: 6px !important;
  }
  .dropdown {
    margin-bottom: 15px !important;
  }
  .pull-right {
    float: none !important;
    margin-left: 0px !important;
  }
  .user-page.section-padding select[data-v-a2d10c0c] {
    padding: 0px 0px;
  }
  .for-responsive-width {
    margin-top: 14px !important;
    width: 100px !important;
  }
  .for-inline {
    flex-direction: column !important;
    float: left !important;
  }
  .for-the-line {
    flex-direction: column;
  }
  .txt-style {
    text-align: justify !important;
  }
  .text-style-for-mob {
    text-align: justify !important;
    width: 100% !important;
  }

  .width-fix {
    /* width: 250px; */
    margin-right:10px !important;
  }
  .yellew-text {
    width: 100% !important;
  }
  .res-yellew-text {
    margin-left: -0px !important;
  }
}

div >>> .popover-body {
  background: #69ccf3;
  color: white;
}

div >>> .bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #69ccf3 !important;
}
</style>
